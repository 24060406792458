import React, { Component } from 'react';
import {
    Row,
    Table,
    Card,
    Container,
    Form,
    Breadcrumb,
    Col,
    Button,
    Modal,
    Alert
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import CustomerOrderService from '../../Services/CustomerOrderService';
import ZoneService from '../../Services/ZoneService';

export default class ShopComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            orders: [],
            id:String,
            barcode:String,
            zone:String,
            zones:[],
            spaceRequired:String,
            spaceAvailable:String
        };
    }
    openModal = (id) => {
        this.setState({ openModal: true });
        this.setState({id:id});
        console.log(id);
    }
  
    closeModal = () => {
        this.setState({ openModal: false });
    }
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(this.state);
        });
    }
    acceptOrder=()=>{
        let dataVal={
            orderId:this.state.id,
            zoneId:this.state.zone
        };
        CustomerOrderService.completeOrderItem(dataVal)
            .then(res => {
                console.log('success');
                console.log(res.data);
                CustomerOrderService.getShopData()
                    .then(res => {
                        this.setState({ orders: res.data ||[]});
                    })
                    .catch(res => {
                        console.log('failure in mount filterOrders');
                        console.log(res.data);
                    });
            })
            .catch(res => {
                console.log('failure');
                console.log(res.data);
            });
        this.setState({ openModal: false });
    }
    componentDidMount () {
        console.log('Mount');
        CustomerOrderService.getShopData()
            .then(res => {
                this.setState({ orders: res.data ||[]});
            })
            .catch(res => {
                console.log('failure in mount ');
                console.log(res.data);
            });
        ZoneService.getAllZonesWithoutPagination()
            .then(res => {
                this.setState({ zones: res.data.zones ||[]});
                console.log(res.data.zones);
            })
            .catch(res => {
                console.log('failure in getting zones');
                console.log(res.data);
            });
    }
    render() {
        let { orders,barcode,zones,zone,spaceRequired } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainOrder'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Shop</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/shop'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Shop</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No</th>
                                        <th>Note</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Product</th>
                                        <th>Variant Name</th>
                                        <th>Required Qty</th>
                                        <th>Completed Qty</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        orders.map((order, idx) => (
                                            <tr key={idx}>
                                                <td>{idx+1}</td>
                                                <td>{order.orderNumber}</td>
                                                <td>{order.remark}</td>
                                                <td>{order.categoryId}</td>
                                                <td>{order.subCategoryId}</td>
                                                <td>{order.productName}</td>
                                                <td>{order.insertedDate}</td>
                                                <td>{order.quantity}</td>
                                                <td>{order.completedQuantity}</td>
                                                <td><Button
                                                    variant='primary'
                                                    className='ml-2'
                                                    onClick={()=>this.openModal(order.orderId)}
                                                >
                                                    Start
                                                </Button></td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <Modal
                                className='textCenter'
                                show={this.state.openModal}
                                onHide={this.closeModal}
                            >
                                <Modal.Header closeButton>Product</Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group controlId='barcode'>
                                            <Form.Label>Barcode </Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='barcode'
                                                onChange={this.changeHandler}
                                                value={barcode}
                                                placeholder='Scan your barcode'
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='zone'>
                                            <Form.Label>Zone</Form.Label>
                                            <Form.Control
                                                as='select'
                                                name='zone'
                                                onChange={this.changeHandler}
                                                value={zone}
                                            >
                                                <option>Select zone</option>
                                                {zones.map((value, idx) => (
                                                    <option key={idx} value={value.zoneId}>
                                                        {value.zoneName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='secondary' onClick={this.closeModal}>
                                    Close
                                    </Button>
                                    <Button variant='success' onClick={()=>this.acceptOrder()}>
                                    Submit
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}
