import React, { Component } from 'react';
import {
    Card,
    Container,
    Form,
    Button,
    Breadcrumb,
    Row,
    Table,
    Spinner,
    Col
} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './operatorAllocation.css';
import ListBox from 'react-listbox';
import 'react-listbox/dist/react-listbox.css';
import OperatorAllocationService from '../../../Services/OperatorAllocationService';
import MaterialTable from 'material-table';

export default class OperatorAllocation extends Component {
    constructor (props) {
        super(props);
        this.state = {
            operator: {
                areaId: Number,
                operatorId: Number
            },
            areas: {},
            operatorData: {},
            operatorType:'all'
        };
    }

    componentDidMount () {
        console.log('Mount');
        
        OperatorAllocationService.getAllAreaUsers('all').then(res=>{
            this.setState({ operators: res.data.operators });
            console.log('On Page Load for Operators');
        });

        OperatorAllocationService.getAllAreas().then(res=>{
            var areaList = {};
            res.data.areas.map(item => {
                areaList[item.areaId] = item.areaName;
            });
            this.setState({ areas: areaList });
            console.log('On Page Load for Area');
        });
    }

    opTypeChangeHandler = (e) => {
        this.setState({ operatorType : e.target.value});
        OperatorAllocationService.getAllAreaUsers(e.target.value).then(res=>{
            if(typeof res.data.operators !== undefined ){
                this.setState({ operators: res.data.operators });
                console.log('After Selecting the Operators Type');
            }
        });
    }

    render () {
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        
        const columns =[
            {
                title :'First Name',
                field : 'firstName',
                editable: 'never',
            },
            {
                title :'Last Name',
                field : 'lastName',
                editable: 'never',
            },
            {
                title :'Area Name',
                field : 'areaId',
                editable: 'onUpdate',
                lookup: this.state.areas
            }            
        ];
        return (
            <div>
                <Container className='mainAllocation'>
                    <Row className='breadcrum'>
                        <Col>
                            <h4>Operator Allocation</h4>
                        </Col>
                        <Col>
                            <Breadcrumb className='alignRight'>
                                <Breadcrumb.Item>
                                    <Link to='/'>
                                        <FaHome />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Operator Allocation</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <ColoredLine color='grey' />
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='opTypeId'>
                                <Form.Label>Operator Type </Form.Label>
                                <Form.Control
                                    as='select'
                                    name='opTypeId'
                                    onChange={this.opTypeChangeHandler}
                                    value={this.state.operatorType}
                                >
                                    <option value="all">All Operators</option>
                                    <option value="free">Free Operators</option>
                                    <option value="allocated">Allocated Operators</option>                                    
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='operatorCard'>
                                <MaterialTable title="Operator Allocation Table"
                                    data= {this.state.operators}
                                    columns={columns}
                                    options={{selection:true, search:true, paging:true.valueOf, pageSizeOptions: [5, 10, 20]}}
                                    onSelectionChange={(rows) => {
                                        this.setState({operator : {
                                            areaId: 0, 
                                            operators:[]
                                        }});
                                        rows.map((row) => {
                                            this.state.operator.operators.push(row.operatorId);
                                        });
                                    }}
                                    actions={[
                                        {
                                            icon: 'save',
                                            tooltip: 'Save User',
                                            onClick: (event, rowData) => alert('You saved ' + rowData.name)
                                        }
                                    ]}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    const dataUpdate = [...this.state.operators];
                                                    const index = oldData.tableData.id;
                                                    dataUpdate[index] = newData;
                                                    console.log(dataUpdate);
                                                    this.setState({operators : dataUpdate});
                                                    this.setState({operator : {
                                                        areaId: newData.areaId, 
                                                        operatorId:newData.operatorId
                                                    }});

                                                    OperatorAllocationService.saveOperator(this.state.operator);
                          
                                                    resolve();
                                                }, 1000);
                                            })
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        );
    }
}
