import React, { Component } from 'react';
import AuthenticationService from '../../Services/AuthenticationService';
import Footer from '../Site/Footer/FooterComponet';
import { Row, Col, Card, Container } from 'react-bootstrap';
import jwt from 'jwt-decode';
import * as Io from 'react-icons/io';
import HeaderComponent from '../Site/Header/HeaderComponent';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './modal.css'
class LoginComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            hasLoginFailed: false,
            showSuccessMessage: false,
            accessToken: '',
            role: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.loginClicked = this.loginClicked.bind(this);
    }

    openModal = () => {
        this.setState({ openModal: true });
    }
  
    closeModal = () => {
        this.setState({ openModal: false });
    }

    handleChange (event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    loginClicked () {
        AuthenticationService.executeJwtAuthenticationService(
            this.state.username,
            this.state.password
        )
            .then(response => {
                var token = response.data.jwt;
                this.setState({ accessToken: token });
                const user = jwt(token);
                console.log(user)
                this.setState({ role: user.authorities });
                console.log(user);
                AuthenticationService.setRole(user.authorities);
                AuthenticationService.registerSuccessfulLoginForJwt(
                    user.username,
                    response.data.jwt
                );
                sessionStorage.setItem('loggedInUser',user.authorities)
                sessionStorage.setItem('userid',user.userid)
                switch(user.authorities){
                case 'ROLE_Administrator':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/home');
                    break;
                case 'ROLE_Stores':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/storeInventory');
                    break;
                case 'ROLE_Operator':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/shop');
                    break;
                case 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/home');
                    break;
                }
                
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                this.setState({ showSuccessMessage: false });
                this.setState({ hasLoginFailed: true });
            });
    }

    render () {
        return (
            <>
                <Modal show={this.state.openModal} 
                size="xl"
                style={{height:"100vh"}}
                className="modalScreen"
                onHide={this.closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <iframe style={{width:'100%',height:'100%'}} id="iframe"
                        src="https://www.etechtracker.com/trackingLink.htm?token=5037%2300000020231103%2300000020240331">
                    </iframe>
                    </Modal.Body>
                </Modal>
                <HeaderComponent />
                <Container className='themed-container' fluid={true}>
                    <Row>
                    {/* <Button
                        variant='danger'
                        className='ml-2'
                        onClick={this.openModal}
                    >
                        OPEN MODAL
                    </Button> */}
                        <Col
                            sm={12}
                            md={4}
                            lg={12}
                            className='mt-5 mb-5 d-flex justify-content-center'
                        >
                            <Card
                                className='text-center'
                                bg='light'
                                style={{ width: '18rem' }}
                            >
                                <Card.Header>
                                    <Card.Title>
                                        <Io.IoIosPerson style={{ fontSize: 60 }} />
                                    </Card.Title>
                                    <Card.Subtitle>Login</Card.Subtitle>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {this.state.hasLoginFailed && (
                                            <div className='alert alert-warning'>
                                                Invalid Credentials
                                            </div>
                                        )}
                                        <div>
                                            <Card.Title>User Name</Card.Title>
                                            <input
                                                type='text'
                                                name='username'
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <Card.Title>Password</Card.Title>
                                            <input
                                                type='password'
                                                name='password'
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-success mt-2 justify-content-center'
                                            onClick={this.loginClicked}
                                        >
                      Login
                                        </button>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

export default LoginComponent;
