import React, { Component } from 'react';
import {
    Row,
    Col,
    Tabs,
    Tab,
    Form,
    Card,
    Table,
    Button,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import './materialRequest.css';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ClosedOrder from './ClosedOrder';
import MaterialService from '../../Services/MaterialService';
import MaterialOrder from '../../Services/MaterialOrder';
export default class MaterialRequest extends Component {
    constructor (props) {
        super(props);
        this.state = {
            materials: [],
            material:String,
            quantity: Number,
            stores: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    componentDidMount () {
        console.log('Mount');
        MaterialService.getAllMaterialsWithoutPagination().then(res => {
            this.setState({ materials: res.data.materials ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        console.log('Mount');
        let activePage = this.state.currentPage;
        MaterialOrder.getAllOpenStore(activePage).then(res => {
            this.setState({ stores: res.data.storeOrders||[] });
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        MaterialOrder.getAllOpenStore(page).then(res => {
            this.setState({ stores: res.data.storeOrders||[] });
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(this.state);
        });
    }
    submitMaterialRequest = e => {
        e.preventDefault();
        let materialOrder = {
            materialId: this.state.material,
            quantity:this.state.quantity,
            remark:'remark added'
        };
        console.log(materialOrder);
        MaterialOrder
            .addStoreOrder(materialOrder)
            .then(res => {
                console.log('success');
                console.log(res.data);
                NotificationManager.success('Material has been added', 'Success');
                let activePage = this.state.currentPage;
                MaterialOrder.getAllOpenStore(activePage).then(res => {
                    this.setState({ stores: res.data.storeOrders||[] });
                    console.log('Mount2');
                    console.log(res.data);
                    const totalPages = res.data.totalPages;
                    const totalItems = res.data.totalItems;

                    this.setState({ totalPages: totalPages });
                    this.setState({ totalItems: totalItems });
                });
            })
            .catch(res => {
                console.log('failure');
                console.log(res.data);
            });
    }
    render () {
        let {
            materials,
            material,
            quantity
        } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainMaterial'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Material Request</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/materialRequest'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Material Request</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Form>
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='material'>
                                <Form.Label>Material Name</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='material'
                                    onChange={this.changeHandler}
                                    value={material}
                                >
                                    <option>Select Material</option>
                                    {materials.map((material, idx) => (
                                        <option key={idx} value={material.materialId}>
                                            {material.materialName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='quantity'>
                                <Form.Label>Quantity </Form.Label>
                                <Form.Control
                                    type='number'
                                    name='quantity'
                                    onChange={this.changeHandler}
                                    value={quantity}
                                    placeholder='Quantity'
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Button
                                className='alignDown mb-3'
                                variant='success'
                                type='submit'
                                onClick={this.submitMaterialRequest}
                            >
                            Send Request
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Tabs defaultActiveKey="openOrder" 
                    id="controlled-tab-example">
                    <Tab eventKey="openOrder" title="Open Order">
                        <Container className='mainStore'>
                            <Row>
                                <Card className='customerCard'>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Material Name</th>
                                                <th>Quantity</th>
                                                <th>Operator Name</th>
                                                <th>Request Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.stores.length === 0 ? (
                                                <div></div>
                                            ) : (
                                                this.state.stores.map((store, idx) => (
                                                    <tr key={idx}>
                                                        <td>{store.materialName}</td>
                                                        <td>{store.orderQuantity}</td>
                                                        <td>{store.operatorName}</td>
                                                        <td>{store.insertedDate}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                </Card>
                            </Row>
                            <div className='d-flex justify-content-center mt-2 mb-2'>
                                <Pagination
                                    hideNavigation
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.totalItems}
                                    pageRangeDisplayed={10}
                                    itemClass='page-item'
                                    linkClass='btn btn-light'
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <NotificationContainer />
                        </Container>
                    </Tab>
                    <Tab eventKey="closedOrder" title="Closed Order">
                        <ClosedOrder/>
                    </Tab>
                </Tabs>
            </Container>
        );
    }
}
