import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Tooltip,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './category.css';
import productService from '../../../Services/ProductService';
import Pagination from 'react-js-pagination';

export default class CategoryComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            categories: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        productService.getAllCategories(page).then(res => {
            this.setState({ categories: res.data.categories||[] });
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        productService.getAllCategories(activePage).then(res => {
            this.setState({ categories: res.data.categories||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    render () {
        let { currentPage } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainCategory'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Category</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Configuration</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Category</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addCategory'>
                        <Button className='mr-3' variant='light'>
              Add Category
                        </Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Category Name</th>
                                        <th>Inserted Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categories.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.categories.map((category, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1 + currentPage * 10}</td>
                                                <td>{category.categoryName}</td>
                                                <td>{category.insertedDate}</td>
                                                <td>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                        <Link
                                                            to={{
                                                                pathname: '/updateCategory',
                                                                state: { id: category.id }
                                                            }}
                                                        >
                                                            <Button variant='success'>
                                                                <BiEdit />
                                                            </Button>
                                                        </Link>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container>
        );
    }
}
