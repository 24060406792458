import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Card,
  Container,
  Modal,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import "../Zone/zone.css";
import ZoneService from "../../../Services/ZoneService";
import Pagination from "react-js-pagination";
import customerService from "../../../Services/CustomerService";
import masterDataService from "../../../Services/MasterDataService";
import RecipeService from "../../../Services/RecipeService";
export default class RecipeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      productList: [],
      productRecipeList: [],
      selectedProductId: "",
      selectedRecipeId: "",
      materialCategoryList: [],
      materialList: [],
      customers: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
      varientsTableData: [],
      materialListToShow: [],
      selectedProductIdandName: {},
      materialName: String,
      materialCategoryName: String,
      quantity: Number,
      cost: Number,
      materialId: "",
      materialCategoryId: Number,
      // productName: String,
    };
  }
  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  //handle page change click
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    ZoneService.getAllZones(page).then((res) => {
      this.setState({ zones: res.data.zones || [] });
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
  }

  componentDidMount() {
    //for product list-dropdown
    RecipeService.getProductList("").then((res) => {
      this.setState({ productList: res.data.products || [] });
    });

    RecipeService.getMaterialCatogoryList().then((res) => {
      this.setState({ materialCategoryList: res.data || [] });
    });

    RecipeService.getMaterialList().then((res) => {
      this.setState({ materialList: res.data.materials || [] });
    });
  }

  //add to existing recipe button click-put call
  handleAddExistingBtn = (e) => {
    e.preventDefault();
    const arr = this.state.materialListToShow.filter((val) => {
      return val.materialId == this.state.materialId;
    });

    let recipeId = this.state.selectedRecipeId;

    let recipe = {
      materialName: arr[0].materialName,
      materialCategoryName: arr[0].categoryName,
      quantity: this.state.quantity,
      cost: this.state.cost,
      materialId: arr[0].materialId,
      materialCategoryId: arr[0].categoryId,
    };

    const data = {
      productId: this.state.selectedProductIdandName.productId,
      productName: this.state.selectedProductIdandName.productName,
      // variantName: "V0",
      variantItems: [recipe],
    };

    RecipeService.updateExistingRecipe(data, recipeId)
      .then((res) => {
        console.log("recipe added successfully");
        this.setState({
          varientsTableData: res?.data?.variantItems,
        });
        // this.setState({ productName: "" });
        // this.setState({ recipeName: "" });
        // this.setState({ materialListToShowName: "" });
        // this.setState({ materialCategory: "" });
        // this.setState({ quantity: "" });
        // this.setState({ cost: "" });
      })
      .catch((res) => {
        console.log("recipe not added it is failed");
      });
  };

  //1st dropdown product list-change handler
  productListchangeHandler = (e) => {
    console.log("id:-", e.target);

    const arr = this.state.productList.filter(
      (val) => val.productId == e.target.value
    );
    this.setState({ selectedProductIdandName: arr[0] });

    RecipeService.getProductRecipeList(e.target.value).then((res) => {
      this.setState({ productRecipeList: res.data || [] });
    });
  };

  //2nd dropdown product list-change handler
  productRecipeListchangeHandler = (e) => {
    const selectedRecipeArr = this.state.productRecipeList.filter(
      (val) => val.recipeId == e.target.value
    );
    this.setState({ varientsTableData: selectedRecipeArr[0]?.variantItems });
    this.setState({ selectedRecipeId: selectedRecipeArr[0]?.recipeId });
  };

  //3rd dropdown product list-change handler
  materialCategoryListchangeHandler = (e) => {
    const filteredArr = this.state.materialList.filter((val) => {
      return val.categoryId == e.target.value;
    });

    this.setState({ materialListToShow: filteredArr });
    this.setState({ materialCategoryName: e.target.value }, () => {});
  };

  //4th dropdown material list
  materialChangeHandler = (e) => {
    this.setState({ materialId: e.target.value }, () => {});
  };

  //quantity textbox value
  quantityChangeHandler = (e) => {
    this.setState({ quantity: e.target.value }, () => {});
  };

  //cost textbox value
  costChangeHandler = (e) => {
    this.setState({ cost: e.target.value }, () => {});
  };

  //to remove record from table
  removeRecord = (val) => {
    const filteredRecord = this.state.varientsTableData.filter(
      (item) => item.id != val
    );

    let recipeId = this.state.selectedRecipeId;
    let id = val;

    RecipeService.removeMaterial(recipeId, id).then(() => {
      this.setState({ varientsTableData: filteredRecord });
    });
  };

  render() {
    let {
      productName,
      quantity,
      cost,
      currentPage,
      productRecipeList,
      materialCategoryList,
      materialList,
      productList,
      recipeName,
      materialCategory,
      materialListName,
      materialListToShow,
      materialListToShowName,
    } = this.state;

    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );

    return (
      <Container className="mainZone">
        <Row className="breadcrum">
          <Col>
            <h4>Recipe</h4>
          </Col>
          <Col>
            <Breadcrumb className="alignRight">
              <Breadcrumb.Item>
                <Link to="/home">
                  <FaHome />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/home"> Configuration</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Recipe</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <ColoredLine color="grey" />
        <div className="m-2">
          <Link to="/addrecipe">
            <Button>Add Recipe</Button>
          </Link>
        </div>
        <br />
        <Form>
          <Row>
            <Col lg={4} md={4} sm={4} xs={12}>
              <Form.Group controlId="productName">
                <Form.Label>Product List </Form.Label>
                <Form.Control
                  as="select"
                  name="productName"
                  onChange={this.productListchangeHandler}
                  value={productName}
                >
                  <option key="blankChoice" hidden value>
                    Select Product
                  </option>
                  {productList.map((val, idx) => (
                    <option key={idx} value={val.productId}>
                      {val.productName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col lg={4} md={6} sm={6} xs={12}>
              <Form.Group controlId="productRecipeList">
                <Form.Label>Product Recipe List </Form.Label>
                <Form.Control
                  as="select"
                  name="productRecipeList"
                  onChange={this.productRecipeListchangeHandler}
                  value={recipeName}
                >
                  <option key="blankChoice" hidden value>
                    Select Recipe
                  </option>
                  {productRecipeList.map((val, idx) => (
                    <option key={idx} value={val.recipeId}>
                      {/* {val.productName} */}
                      {val.variantName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={6} sm={6} xs={12}>
              <Form.Group controlId="materialCategoryList">
                <Form.Label>Material Category List </Form.Label>
                <Form.Control
                  as="select"
                  name="materialCategoryList"
                  onChange={this.materialCategoryListchangeHandler}
                  value={materialCategory}
                >
                  <option key="blankChoice" hidden value>
                    Select Category
                  </option>
                  {materialCategoryList.map((val, idx) => (
                    <option key={idx} value={val.categoryId}>
                      {val.categoryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} sm={6} xs={12}>
              <Form.Group controlId="materialList">
                <Form.Label>Material List </Form.Label>
                <Form.Control
                  as="select"
                  name="materialList"
                  onChange={this.materialChangeHandler}
                  value={materialListToShowName}
                >
                  <option key="blankChoice" hidden value>
                    Select Material
                  </option>
                  {materialListToShow?.map((val, idx) => (
                    <option key={idx} value={val.materialId}>
                      {val.materialName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} sm={6} xs={12}>
              <Form.Group controlId="quantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  onChange={this.quantityChangeHandler}
                  value={quantity}
                  placeholder="Enter Quantity"
                  autoComplete="off"
                ></Form.Control>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} sm={6} xs={12}>
              <Form.Group controlId="cost">
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  type="number"
                  name="cost"
                  onChange={this.costChangeHandler}
                  value={cost}
                  placeholder="Enter Cost"
                  autoComplete="off"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col lg={3} md={3} sm={6} xs={12} style={{ marginTop: "25px" }}>
              <Button onClick={this.handleAddExistingBtn}>
                Add to existing Recipe
              </Button>
            </Col>
          </Row>
        </Form>
        <br />

        <Row>
          <Col>
            <Card className="customerCard">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Material Category</th>
                    <th>Material Name</th>
                    <th>Material Quantity</th>
                    <th>Material Cost</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state?.varientsTableData.map((val, idx) => {
                    return (
                      <>
                        <tr key={idx}>
                          <td>{val.materialCategoryName}</td>
                          <td>{val.materialName}</td>
                          <td>{val.quantiy}</td>
                          <td>{val.cost}</td>
                          <td>
                            <Button onClick={() => this.removeRecord(val.id)}>
                              Remove
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
              <Modal
                className="textCenter"
                show={this.state.openModal}
                onHide={this.closeModal}
              >
                <Modal.Header closeButton>Delete</Modal.Header>
                <Modal.Body>Do you really want to delete?</Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={this.closeModal}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
