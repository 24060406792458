import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Table,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import MaterialOrder from '../../../Services/MaterialOrder';
import SupplierService from '../../../Services/SupplierService';
import MasterDataService from '../../../Services/MasterDataService';
import MaterialService from '../../../Services/MaterialService';
import logo from '../../../Images/blitzer.png';
export default class EditPurchaseOrder extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            supplierProducts:[],
            detailRequests:[],
            currentPage: 0,
            totalPages: null,
            totalItems: null,
            businessNumber:String,
            materialName:String,
            purchaseOrderNumber:String,
            purchaseOrderDate:Date,
            supplierId:String,
            supplierName:String,
            materialId:String,
            quantity:Number,
            poDate:Date,
            description:String,
            supplierId:String 
        };
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        console.log(activePage);
        let id = this.state.id;
        console.log(id);
        MaterialOrder.getSinglePurchaseOrder(id).then(res => {
            console.log(res.data);
            this.setState({detailRequests: res.data.purchaseOrderItems ||[],supplierId:res.data.supplierId,poDate:res.data.purchaseOrderDate,supplierName:res.data.supplierName});
            MaterialService.getAllMaterialsBySupplier(res.data.supplierId).then(res => {
                this.setState({ supplierProducts: res.data.mappings||[]});
            });
        });
        MaterialOrder.preOrderDetail().then(res=>{
            this.setState({ purchaseOrderNumber: res.data.purchaseOrderNumber});
            this.setState({ purchaseOrderDate: res.data.purchaseOrderDate});
        });
        MasterDataService.getTax().then(res => {
            this.setState({ businessNumber: res.data.businessNumber});
        });
    }
    changeHandler = e => {
        switch (e.target.name) {
        case 'materialId':
            this.setState({
                materialName: (this.state.supplierProducts.filter(el => el.materialId == e.target.value))[0].materialName
            });
            break;
        default:
            break;
        }
        this.setState({ [e.target.name]: e.target.value });
    }
    addtoList = e =>{
        console.log('inside add to List');
        e.preventDefault();
        let detail={
            description:this.state.description,
            materialId:this.state.materialId,
            supplierId:this.state.supplierId,
            materialName:this.state.materialName,
            quantity:this.state.quantity,
            poDate:this.state.poDate
        };
        this.setState({
            detailRequests: this.state.detailRequests.concat(detail)
        });
        this.setState({materialId:''});
        this.setState({quantity:''});
        this.setState({description:''});
        console.log(detail);
    }
    removeFromList=(detail)=>{
        this.setState(prevState => ({
            detailRequests: prevState.detailRequests.filter(el => el !== detail)
        }));
    }
    submit=e=>{
        let id=this.state.id;
        let order = {
            purchaseOrderDate: this.state.purchaseOrderDate,
            supplierId: this.state.supplierId,
            detailRequests: this.state.detailRequests
        };
        console.log(order);
        MaterialOrder
            .updatePurchaseOrder(id,order)
            .then(res => {
                console.log('success');
                console.log(res.data);
                // eslint-disable-next-line react/prop-types
                this.props.history.push('/purchaseOrder');
            })
            .catch(res => {
                console.log('failure');
                console.log(res.data);
            });
    }
    render() {
        let {description,
            poDate,
            supplierId,
            supplierProducts,
            supplierName,
            purchaseOrderNumber,
            purchaseOrderDate,
            businessNumber,
            materialId,
            quantity}=this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainPurchase'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Purchase Order</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Purchase</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/purchaseOrder'>Purchase Order</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Purchase Order</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Form>
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='poDate'>
                                <Form.Label><b>PO Date</b></Form.Label>
                                <Form.Control
                                    type='date'
                                    name='poDate'
                                    value={poDate}
                                    disabled
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='supplierId'>
                                <Form.Label><b>Supplier</b></Form.Label>
                                <Form.Control
                                    as='select'
                                    name='supplierId'
                                    value={supplierId}
                                    disabled
                                ><option>{supplierName}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='materialId'>
                                <Form.Label><b>Supplier Product </b></Form.Label>
                                <Form.Control
                                    as='select'
                                    name='materialId'
                                    onChange={this.changeHandler}
                                    value={materialId}
                                ><option>Select Supplier Product </option>
                                    {supplierProducts.map((material, idx) => (
                                        <option key={idx} value={material.materialId}>
                                            {material.materialName}
                                        </option>
                                    ))}</Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>  
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='description'>
                                <Form.Label><b>Description</b></Form.Label>
                                <Form.Control
                                    type='text'
                                    name='description'
                                    onChange={this.changeHandler}
                                    value={description}
                                    placeholder='Enter description'
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group controlId='quantity'>
                                <Form.Label><b>Quantity</b></Form.Label>
                                <Form.Control
                                    type='number'
                                    name='quantity'
                                    onChange={this.changeHandler}
                                    value={quantity}
                                    placeholder='Enter required quantity'
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} >
                            <Button
                                variant='primary'
                                className='downButtonStore'
                                onClick={this.addtoList}
                            >Add
                            </Button>
                            <Link
                                to={'/purchaseOrder'}
                            >
                                <Button variant='secondary'className='downButtonStore'>
                                Back
                                </Button>
                            </Link>
                        </Col>
                    </Row>               
                </Form>
                <Row>
                    <Card className='customerCard mb-3'>
                        <Card.Header className='text-center'>
                            <Card.Title>
                                <h4>Add Purchase Order</h4>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={5} md={4} sm={6} xs={12}>
                                    <img
                                        src={logo}
                                        width='100%'
                                        height='auto'
                                        className='d-inline-block align-top'
                                        alt='SG'
                                    ></img>
                                </Col>
                                <Col lg={2} md={4} sm={0} xs={0}>
                                </Col>
                                <Col lg={5} md={4} sm={6} xs={12}>
                                    <Row className='mt-3'>
                                        <Col>
                                            <h2>Purchase Order</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <bold>Order Number: {purchaseOrderNumber}</bold>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <bold>PO Date: {purchaseOrderDate} </bold>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <bold>Business Number: {businessNumber}</bold>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Supplier Product</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.detailRequests.length === 0 ? (
                                                <div></div>
                                            ) : (
                                                this.state.detailRequests.map((detail, idx) => (
                                                    <tr key={idx}>
                                                        <td>{detail.materialName}</td>
                                                        <td>{detail.description}</td>
                                                        <td>{detail.quantity}</td>
                                                        <td><Button
                                                            variant='danger'
                                                            onClick={()=>{this.removeFromList(detail);}}
                                                        >Remove
                                                        </Button></td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                    <Button
                                        variant='success'
                                        onClick={this.submit}
                                    >Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        );
    }
}
