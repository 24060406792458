import axios from "axios";
// const API_URL = 'http://localhost:9090';
//const API_URL = 'http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = "http://35.200.212.170"; ///auth-server/api/"
const API_URL = process.env.REACT_APP_BASE_URL;
export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";

export const USER_NAME_SESSION_ATTRIBUTE_TOKEN = "authenticatedUserToken";

export const USER_ROLE_SESSION_ATTRIBUTE_TOKEN = "authenticatedUserRole";

class AuthenticationService {
  tokenUser = "";

  executeJwtAuthenticationService(username, password) {
    // return axios.post(`${API_URL}/auth-server/authenticate`, {
    console.log(process.env.REACT_APP_BASE_URL);
    return axios.post(`${API_URL}/auth-server/authenticate`, {
      username: username,

      password: password,
    });
  }

  registerSuccessfulLoginForJwt(username, token) {
    // console.log('usertoken ' + token);

    // console.log(username);

    this.tokenUser = token;

    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);

    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_TOKEN, token);
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    sessionStorage.removeItem(USER_ROLE_SESSION_ATTRIBUTE_TOKEN);
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_TOKEN);
    sessionStorage.removeItem("loggedInUser");
    sessionStorage.removeItem("userid");
    localStorage.clear();
    sessionStorage.clear();
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    // console.log(user);
    if (user === null) return false;
    return true;
  }

  getLoggedInUserName() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);

    if (user === null) return "";

    return user;
  }

  getUsername() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);

    // console.log(user);

    return user;
  }

  getToken() {
    let userT = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_TOKEN);

    // console.log('usertoken in Authentication Service' + userT);

    return "Bearer " + userT;
  }

  setRole(role) {
    // console.log(role);
    sessionStorage.setItem(USER_ROLE_SESSION_ATTRIBUTE_TOKEN, role);
  }

  getRole() {
    return sessionStorage.getItem(USER_ROLE_SESSION_ATTRIBUTE_TOKEN);
  }
}

export default new AuthenticationService();
