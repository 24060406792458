import React, { Component } from "react";
import {
  Row,
  Table,
  Card,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";
import CustomerOrderService from "../../Services/CustomerOrderService";
import RackService from "../../Services/RackService";
import Pagination from "react-js-pagination";
import AuthenticationService from "../../Services/AuthenticationService";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

export default class NonDipatchedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatches: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
      id: String,
      dispatchDetail: String,
      dispatchDate: String,
      rack: String,
      zone: String,
      racks: [],
    };
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    CustomerOrderService.getAllNonDispatch(page)
      .then((res) => {
        res.data.dispatches.map((item, idx) => {
          item.id = idx + 1 + page * 10;
          return item;
        });

        return res;
      })
      .then((res) => {
        this.setState({ dispatches: res.data.dispatches || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
      });
  }
  componentDidMount() {
    console.log("Mount");
    let activePage = this.state.currentPage;
    CustomerOrderService.getAllNonDispatch(activePage)
      .then((res) => {
        (res.data.dispatches || []).map((item, idx) => {
          item.id = idx + 1 + activePage * 10;
          return item;
        });

        return res;
      })
      .then((res) => {
        this.setState({ dispatches: res.data.dispatches || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
      });
    RackService.getDashboardRacks().then((res) => {
      this.setState({ racks: res.data.racks });
    });
  }
  openModal = (id, zone) => {
    this.setState({ openModal: true, zone: zone, id: id });
    console.log(id);
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  dispatchOrder = (e) => {
    let rack = this.state.rack;
    let order = {
      orderId: this.state.id,
      dispatchDate: this.state.dispatchDate,
      dispatchNote: this.state.dispatchDetail,
      rackIds: [rack],
    };
    console.log(order);
    let activePage = this.state.currentPage;
    CustomerOrderService.dispatchOrder(order)
      .then((res) => {
        res.data.dispatches.map((item, idx) => {
          item.id = idx + 1 + order * 10;
          return item;
        });

        return res;
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ openModal: false });
        CustomerOrderService.getAllNonDispatch(activePage)
          .then((res) => {
            res.data.dispatches.map((item, idx) => {
              item.id = idx + 1 + activePage * 10;
              return item;
            });

            return res;
          })
          .then((res) => {
            this.setState({ dispatches: res.data.dispatches || [] });
            console.log("Mount2");
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;
            this.setState({ openModal: false });
            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
          });
      });
  };
  render() {
    let userRole = AuthenticationService.getRole();
    let { dispatchDetail, dispatchDate, rack, racks } = this.state;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "id",
        text: "#",
      },
      {
        dataField: "invoiceNumber",
        text: "Invoice Number",
      },
      {
        dataField: "customerName",
        text: "Banner Detail",
        formatter: function (cell, row) {
          return "Banner Name: " + cell;
        },
      },
      {
        dataField: "zoneDetail",
        text: "Zone Detail",
        formatter: function (cell, row) {
          return "Store Name: " + cell; // storeName
        },
      },
      {
        dataField: "totalItems",
        text: "Total Items",
      },
      {
        dataField: "invoiceDate",
        text: "Invoice Data",
      },
      {
        dataField: "dispatchStatus",
        text: "Dispatch Status",
      },
    ];

    return (
      <Container fluid className="mainDispatch">
        <Row>
          <Card className="customerCard">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                page: this.state.currentPage + 1,
                sizePerPage: 10,
                paginationSize: 10,
                alwaysShowAllBtns: true,
                totalSize: this.state.totalItems,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <div className="w-100">
                  <BootstrapTable
                    remote
                    bordered={false}
                    keyField="id"
                    data={this.state.dispatches}
                    columns={columns}
                    wrapperClasses="gh-table"
                    onTableChange={handleTableChange}
                    {...paginationTableProps}
                  />

                  <div className="d-flex justify-content-center">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                  {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                </div>
              )}
            </PaginationProvider>
          </Card>
        </Row>

        {/* <Container className='mainDispatch'>
                <Row>
                    <Card className='customerCard'>
                        <Table striped bordered={ false } hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Invoice Number</th>
                                    <th>Customer Detail</th>
                                    <th>Zone Detail</th>
                                    <th>Total Items</th>
                                    <th>Invoice Date</th>
                                    <th>Dispatch Status</th>
                                    {userRole=='ROLE_Operator' &&(
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dispatches.length === 0 ? (
                                    <div></div>
                                ) : (
                                    this.state.dispatches.map((dispatch, idx) => (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>{dispatch.invoiceNumber}</td>
                                            <td><div>
                                            Customer Name: {dispatch.customerName}
                                            </div>
                                            <div>
                                        Store Name: {dispatch.storeName}
                                            </div></td>
                                            <td>{dispatch.zoneDetail}</td>
                                            <td>{dispatch.totalItems}</td>
                                            <td>{dispatch.invoiceDate}</td>
                                            <td>{dispatch.dispatchStatus}</td>
                                            {userRole=='ROLE_Operator' &&(
                                                <td>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bill Details</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant='success'
                                                                onClick={()=>this.openModal(dispatch.orderId,dispatch.zoneDetail)}
                                                            >
                                                                Dispatch
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </Row>
                
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container> */}

        <Modal
          className="textCenter"
          show={this.state.openModal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <h4>Dispatch Information</h4>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="dispatchDetail">
                <Form.Label>
                  <b>Dispatch Detail</b>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="dispatchDetail"
                  onChange={this.changeHandler}
                  value={dispatchDetail}
                  placeholder="Dispatch Details"
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="dispatchDate">
                <Form.Label>
                  <b>Dispatch Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="dispatchDate"
                  onChange={this.changeHandler}
                  value={dispatchDate}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <b>Zone Orders</b>
                </Form.Label>
                <div>{this.state.zone}</div>
              </Form.Group>
              <Form.Group controlId="rack">
                <Form.Label>
                  <b>Rack </b>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="rack"
                  onChange={this.changeHandler}
                  value={rack}
                  placeholder="Rack"
                >
                  <option>Select Rack</option>
                  {racks.map((rack, idx) => (
                    <option key={idx} value={rack.rackId}>
                      {rack.rackName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  <b>Vehicle Allocation </b>
                </Form.Label>
                <Form.Control as="select" placeholder="Select Vehicle">
                  <option>Select Vehicle</option>
                  <option value="rack1">MH 12 AB 1234</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="danger" onClick={this.dispatchOrder}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
