import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import prouctService from '../../../Services/ProductService';
import './category.css';

export default class UpdateCategory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            category:{
                categoryName: String
            }
        };
    }
      changeHandler = e => {
          let category = this.state.category;
          category[e.target.name] = e.target.value;
          this.setState({ category }, () => console.log(this.state.category));
      }
      componentDidMount () {
          let id = this.state.id;
          prouctService.getSingleCategory(id).then(res => {
              this.setState({ category: res.data||[] });
          });
      }
      updateCategory = e => {
          e.preventDefault();
          let id= this.state.id;
          let category = this.state.category;
          console.log(category);
          prouctService
              .updateCategory(id,category)
              .then(res => {
                  console.log('success');
                  console.log(res.data);
                  // eslint-disable-next-line react/prop-types
                  this.props.history.push('/category');
              })
              .catch(res => {
                  console.log('failure');
                  console.log(res.data);
              });
      }
      render() {
          let { categoryName } = this.state.category;
          const ColoredLine = ({ color }) => (
              <hr
                  style={{
                      color: color,
                      backgroundColor: color,
                      marginTop: 0,
                      height: 1
                  }}
              />
          );
          return (
              <Container className='mainCategory mb-5'>
                  <Row className='breadcrum'>
                      <Col>
                          <h4>Update Category</h4>
                      </Col>
                      <Col>
                          <Breadcrumb className='alignRight'>
                              <Breadcrumb.Item>
                                  <Link to='/home'>
                                      <FaHome />
                                  </Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                  <Link to='/home'> Configuration</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                  <Link to='/category'>Category</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item active>Update Category</Breadcrumb.Item>
                          </Breadcrumb>
                      </Col>
                  </Row>
                  <ColoredLine color='grey' />
                  <Card>
                      <Card.Header className='text-center'>
                          <Card.Title>
                              <h4>Update Category</h4>
                          </Card.Title>
                      </Card.Header>
                      <Card.Body>
                          <Form>
                              <Row>
                                  <Col lg={4} md={6} sm={6} xs={12}>
                                      <Form.Group controlId='categoryName'>
                                          <Form.Label>Category Name</Form.Label>
                                          <Form.Control
                                              type='text'
                                              name='categoryName'
                                              onChange={this.changeHandler}
                                              value={categoryName}
                                              placeholder='Category Name'
                                          ></Form.Control>
                                      </Form.Group>
                                  </Col>
                              </Row>
                              <Row className='centerHorizantalButton'>
                                  <Button
                                      className='mt-3 mr-3'
                                      variant='success'
                                      type='submit'
                                      onClick={this.updateCategory}
                                  >
                      Submit
                                  </Button>
                                  <Link to='/category'>
                                      <Button className='mt-3' variant='light'>
                        Back
                                      </Button>
                                  </Link>
                              </Row>
                          </Form>
                      </Card.Body>
                  </Card>
              </Container>
          );
      }
}
