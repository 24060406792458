import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import supplierService from '../../../Services/SupplierService';
import masterDataService from '../../../Services/MasterDataService';
import './supplier.css';

export default class AddSupplier extends Component {
    constructor (props) {
        super(props);
        this.state = {
            countries: [],
            supplierName: String,
            emailAddress: String,
            phoneNumber: String,
            address: {
                address1: String,
                address2: String,
                city: String,
                region: String,
                province: String,
                country: String
            },
            formErrors: {
                corporateName: '',
                customerName: ''
            },
            errorStatus: false
        };
    }
    componentDidMount () {
        console.log('Mount');
        masterDataService.getAllCountries().then(res => {
            this.setState({ countries: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
    }
    onChange (e) {
        console.log('inside if');
        let address = this.state.address;
        console.log(e.target.name);
        address[e.target.name] = e.target.value;
        this.setState({ address }, () => console.log(this.state.address));
    }

  changeHandler = e => {
      let formErrors = { ...this.state.formErrors };
      //Switch method for tackling particular formError
      switch (e.target.name) {
      case 'corporateName':
          formErrors.id =
          e.target.value.length < 3 ? 'minimum 3 characterss required' : '';
          break;
      case 'customerName':
          formErrors.name =
          e.target.value.length < 3 ? 'minimum 3 characterss required' : '';
          break;
      default:
          break;
      }
      this.setState({ formErrors, [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }

  addSupplier = e => {
      e.preventDefault();
      let supplier = {
          supplierName: this.state.supplierName,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
          address: this.state.address
      };
      supplierService
          .addSupplier(JSON.stringify(supplier))
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/supplier');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { supplierName,  countries, emailAddress, phoneNumber, address } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Supplier</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Profiles</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/Supplier'>Supplier</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Add Supplier</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Add Supplier</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='supplierName'>
                                      <Form.Label>Supplier Name </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='supplierName'
                                          onChange={this.changeHandler}
                                          value={supplierName}
                                          placeholder='Supplier Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='emailAddress'>
                                      <Form.Label>Email Address </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='emailAddress'
                                          onChange={this.changeHandler}
                                          value={emailAddress}
                                          placeholder='Email Address'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='phoneNumber'>
                                      <Form.Label>Phone Number</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='phoneNumber'
                                          onChange={this.changeHandler}
                                          value={phoneNumber}
                                          placeholder='Phone Number'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col>
                                  <Form.Group controlId='address.address1'>
                                      <Form.Label>Address</Form.Label>
                                      <Form.Control
                                          type='textarea'
                                          rows='4'
                                          name='address1'
                                          onChange={e => this.onChange(e)}
                                          value={address.address1}
                                          placeholder='Enter address ...'
                                      />
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.country'>
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='country'
                                          onChange={e => this.onChange(e)}
                                          value={address.country}
                                      >
                                          <option>Select Country</option>
                                          {countries.map((country, idx) => (
                                              <option key={idx} value={country.value}>
                                                  {country.value}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.city'>
                                      <Form.Label>City</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='city'
                                          onChange={e => this.onChange(e)}
                                          value={address.city}
                                          placeholder='City Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.province'>
                                      <Form.Label>Province</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='province'
                                          onChange={e => this.onChange(e)}
                                          value={address.province}
                                          placeholder='Province Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.region'>
                                      <Form.Label>Region</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='region'
                                          onChange={e => this.onChange(e)}
                                          value={address.region}
                                          placeholder='Region Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.addSupplier}
                              >
                  Submit
                              </Button>
                              <Link to='/supplier'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
