import React, { Component } from 'react';
import {
    Row,
    Form,
    Col,
    Button,
    Card,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import CustomerOrderService from '../../Services/CustomerOrderService';
import CustomerService from '../../Services/CustomerService';
import 'react-notifications/lib/notifications.css';

export default class AddOrderExcel extends Component {
    constructor (props) {
        super(props);
        this.state = {
            corporates: [],
            customer: [],
            corporateId: Number,
            customerId: Number,
            file: ''
        };
    }
  changeHandler = e => {
      switch (e.target.name) {
      case 'corporateId':
          CustomerService.getCustomerbyCorporateId(e.target.value).then(res => {
              this.setState({ customer: res.data.customers });
          });
          break;
      default: 
      }
      this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }
  changeHandle (e) {
      console.log('inside if');
      this.setState({ file: e.target.files[0] });
  }
  //data needs to be outputed for the same
  download = e => {
      e.preventDefault();
      console.log('inside download');
      let customerId = this.state.customerId;
      console.log(customerId);
      CustomerOrderService.downloadFileByCustomerId(customerId).then(response => {
          const filename = response.headers
              .get('Content-Disposition')
              .split('filename=')[1];
          response.then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = filename;
              a.click();
          });
      });
  }
  componentDidMount () {
      console.log('Mount');
      CustomerService.getAllCorporates().then(res => {
          this.setState({ corporates: res.data.corporates });
          console.log('Mount2');
          console.log(res.data);
      });
      console.log(this.state.corporateId);
  }
  submitExcel = e => {
      e.preventDefault();
      let formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('userId', '1');
      console.log('data');
      console.log(formData);
      CustomerOrderService.addCustomerOrderExcel(formData)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/addOrder');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { corporates, customer, corporateId, customerId } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      let { file } = this.state;
      return (
          <Container className='mainOrder'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Order</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Order</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/addOrder'>Add Order</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Bulk Order</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Row>
                  <Col>
                      <Card>
                          <Card.Header className='text-center'>
                              <Card.Title>
                                  <h4>Bulk Order</h4>
                              </Card.Title>
                          </Card.Header>
                          <Card.Body>
                              <Row>
                                  <Col>
                                      <Form>
                                          <Row>
                                              <Col lg={3} md={4} sm={6} xs={12}>
                                                  <Form.Group controlId='corporateId'>
                                                      <Form.Label>Corporate Name</Form.Label>
                                                      <Form.Control
                                                          as='select'
                                                          name='corporateId'
                                                          onChange={this.changeHandler}
                                                          value={corporateId}
                                                      >
                                                          <option>Select corporate name</option>
                                                          {corporates.map((corporate, idx) => (
                                                              <option key={idx} value={corporate.corporateId}>
                                                                  {corporate.corporateName}
                                                              </option>
                                                          ))}
                                                      </Form.Control>
                                                  </Form.Group>
                                              </Col>
                                              <Col lg={3} md={4} sm={6} xs={12}>
                                                  <Form.Group controlId='customerId'>
                                                      <Form.Label>Customer Name</Form.Label>
                                                      <Form.Control
                                                          as='select'
                                                          name='customerId'
                                                          onChange={this.changeHandler}
                                                          value={customerId}
                                                      > <option>Select customer name</option>
                                                          {customer.map((cust, idx) => (
                                                              <option key={idx} value={cust.customerId}>
                                                                  {cust.customerName}
                                                              </option>
                                                          ))}
                                                      </Form.Control>
                                                  </Form.Group>
                                              </Col>
                                              <Col lg={3} md={4} sm={6} xs={12} className='ml-2'>
                                                  <Button
                                                      className='mt-3'
                                                      variant='dark'
                                                      type='submit'
                                                      onClick={this.download}
                                                  >
                            Download
                                                  </Button>
                                              </Col>
                                          </Row>
                                      </Form>
                                  </Col>
                              </Row>
                              <Row className='mt-4'>
                                  <Col>
                                      <Form>
                                          <Row>
                                              <Col lg={3} md={4} sm={6} xs={12}>
                                                  <Form.Group>
                                                      <Form.File
                                                          className='position-relative'
                                                          required
                                                          name='file'
                                                          label='Please choose excel to upload'
                                                          vaulue={file}
                                                          onChange={e => this.changeHandle(e)}
                                                          feedbackTooltip
                                                      />
                                                  </Form.Group>
                                              </Col>
                                              <Col lg={3} md={4} sm={6} xs={12}>
                                                  <Button
                                                      className='mt-3 mr-3'
                                                      variant='success'
                                                      type='submit'
                                                      onClick={this.submitExcel}
                                                  >
                            Submit
                                                  </Button>
                                                  <Link to='/addOrder'>
                                                      <Button className='mt-3' variant='light'>
                              Back
                                                      </Button>
                                                  </Link>
                                              </Col>
                                          </Row>
                                      </Form>
                                  </Col>
                              </Row>
                          </Card.Body>
                      </Card>
                  </Col>
              </Row>
          </Container>
      );
  }
}
