import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import productService from '../../../Services/ProductService';
import './category.css';
export default class AddSubCategory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            subCategoryName: String,
            categoryId: Number,
            categories: []
        };
    }
  changeHandler = e => {
      this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }
  componentDidMount () {
      console.log('Mount');
      productService.getAllCategoriesWithoutPagination().then(res => {
          this.setState({ categories: res.data.categories ||[]});
          console.log('Mount2');
          console.log(res.data);
      });
  }
  addSubCategory = e => {
      e.preventDefault();
      let subCategory = {
          subCategoryName: this.state.subCategoryName,
          categoryId: this.state.categoryId
      };
      console.log(subCategory);
      productService
          .addSubCategory(subCategory)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/subCategory');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { categories, subCategoryName, categoryId } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainCategory mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Sub Category</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/category'>Sub Category</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Add Sub Category</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Add Sub Category</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='subCategoryName'>
                                      <Form.Label> Sub Category Name</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='subCategoryName'
                                          onChange={this.changeHandler}
                                          value={subCategoryName}
                                          placeholder='Sub Category Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='categoryId'>
                                      <Form.Label>Category Name</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='categoryId'
                                          onChange={this.changeHandler}
                                          value={categoryId}
                                      >
                                          <option>Select Category</option>
                                          {categories.map((category, idx) => (
                                              <option key={idx} value={category.id}>
                                                  {category.categoryName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.addSubCategory}
                              >
                  Submit
                              </Button>
                              <Link to='/subCategory'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
