
import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Card,
    Container,
    Breadcrumb,
    Button,
    Form,
    Modal
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './order.css';
import CustomerOrderService from '../../Services/CustomerOrderService';
import MasterDataService from '../../Services/MasterDataService';
import CustomerService from '../../Services/CustomerService';
const loggedIn = sessionStorage.getItem('loggedInUser');
import AuthenticationService from '../../Services/AuthenticationService';

export default class OrderStatus extends Component {
    constructor (props) {
        super(props);
        this.state = {
            corporates: [],
            customers: [],
            stores: [],
            years: [],
            weeks: [],
            orders: [],
            data: {
                corporateId: String,
                customerId: String,
                storeId: String,
                year: String,
                weekNumber: String,
                orderStatus: String
            }
        };
    }

    openModal = () => {
        this.setState({ openModal: true });
    }
  
    closeModal = () => {
        this.setState({ openModal: false });
    }

    componentDidMount () {
        console.log('Mount');
        let demoRes = {
            data:{
              corporates:[
                {
                  "corporateId": 1,
                  "corporateName": "DEMO GREENHOUSE 1"
                },
                {
                  "corporateId": 2,
                  "corporateName": "DEMO GREENHOUSE 2"
                },
                {
                  "corporateId": 3,
                  "corporateName": "DEMO GREENHOUSE 3"
                }
              ]
            }
          }
        CustomerService.getAllCorporates()
            .then(res=>{
                // this.setState({corporates:res.data.corporates||[]});
                let userRole = AuthenticationService.getRole();
                if(userRole == 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer'){
                    this.setState({ corporates: demoRes.data.corporates || [] });
                  }else{
                    this.setState({ corporates: res.data.corporates || [] });
                  }
            });
        MasterDataService.getAllYears()
            .then(res => {
                this.setState({ years: res.data ||[]});
            })
            .catch(res => {
                console.log('failure in mount getAllYears');
                console.log(res.data);
            });
        MasterDataService.getAllWeeks()
            .then(res => {
                this.setState({ weeks: res.data ||[]});
            })
            .catch(res => {
                console.log('failure in mount getAllWeeks');
                console.log(res.data);
            });
    }
    displayOrders=e=>{
        let data = this.state.data;
        CustomerOrderService.filterOrders(data)
            .then(res => {
                this.setState({ orders: res.data ||[]});
            })
            .catch(res => {
                console.log('failure in mount filterOrders');
                console.log(res.data);
            });
    }
    changeHandler = e => {
        let data = this.state.data;
        switch (e.target.name) {
        case 'corporateId':
            let id = e.target.value;
            if(id!=''){
                CustomerService.getCustomerbyCorporateId(id)
                    .then(res=>{
                        this.setState({ customers: res.data.customers ||[]});
                        this.setState({stores:[]});
                    });
                break;
            }else{
                this.setState({stores:[],customers:[],corporateId:'',customerId:'',storeId:''});
            }
        case 'customerId':
            let customerId = e.target.value;
            CustomerService.getStorebyCustomerId(customerId)
                .then(res=>{
                    this.setState({ stores: res.data.stores ||[]});
                });
            break;
        default:
            break;
        }
        data[e.target.name] = e.target.value;
        this.setState({ data }, () => console.log(this.state.data));
    }
    render () {
        let userRole = AuthenticationService.getRole();
        let {
            corporates,
            customers,
            stores,
            years,
            weeks
        } = this.state;
        let{
            corporateId,
            customerId,
            storeId,
            year,
            weekNumber,
            orderStatus
        }=this.state.data;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainOrder'>
                <Modal show={this.state.openModal} 
                size="xl"
                style={{height:"100vh"}}
                className="vehicle-tracking"
                onHide={this.closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Live Tracking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <iframe style={{width:'100%',height:'100%'}} id="iframe"
                        src="https://www.etechtracker.com/trackingLink.htm?token=5037%2300000020231103%2300000020240331">
                    </iframe>
                    </Modal.Body>
                </Modal>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Order Status</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Order</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Order Status</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Form>
                    <Row>
                        {/* <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='corporateId'>
                                <Form.Label>Corporate Name</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='corporateId'
                                    onChange={this.changeHandler}
                                    value={corporateId}
                                >
                                    <option value=''>All</option>
                                    {corporates.map((corporate, idx) => (
                                        <option key={idx} value={corporate.corporateId}>
                                            {corporate.corporateName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col> */}
                        {userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? (
                            <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId="corporateId">
                            <Form.Label>Corporate Name</Form.Label>
                            <Form.Control
                                as="select"
                                name="corporateId"
                                onChange={this.changeHandler}
                                value={corporateId}
                            >
                                <option>Select corporate name</option>
                                {corporates.map((corporate, idx) => (
                                <option key={idx} value={corporate.corporateId}>
                                    {corporate.corporateName}
                                </option>
                                ))}
                            </Form.Control>
                            </Form.Group>
                        </Col>
                        ) : (
                        <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="corporateId">
                            <Form.Label>Greenhouse</Form.Label>
                            <Form.Control
                            as="select"
                            name="corporateId"
                            onChange={this.changeHandler}
                            value={corporateId}
                            >
                            <option>Select Greenhouse</option>
                            {corporates.map((corporate, idx) => (
                                <option key={idx} value={corporate.corporateId}>
                                {corporate.corporateName}
                                </option>
                            ))}
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        )}
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='customerId'>
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='customerId'
                                    onChange={this.changeHandler}
                                    value={customerId}
                                >
                                    <option value=''>All</option>
                                    {customers.map((customer, idx) => (
                                        <option key={idx} value={customer.customerId}>
                                            {customer.customerName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='storeId'>
                                <Form.Label>Store Name</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='storeId'
                                    onChange={this.changeHandler}
                                    value={storeId}
                                >
                                    <option value=''>All</option>
                                    {stores.map((store, idx) => (
                                        <option key={idx} value={store.storeId}>
                                            {store.storeName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='year'>
                                <Form.Label>Years</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='year'
                                    onChange={this.changeHandler}
                                    value={year}
                                >
                                    <option value=''>All</option>
                                    {years.map((year, idx) => (
                                        <option key={idx} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='weekNumber'>
                                <Form.Label>Weeks</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='weekNumber'
                                    onChange={this.changeHandler}
                                    value={weekNumber}
                                >
                                    {weeks.map((weekNumber, idx) => (
                                        <option key={idx} value={weekNumber}>
                                            {weekNumber}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Form.Group controlId='orderStatus'>
                                <Form.Label>Order Status</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='orderStatus'
                                    onChange={this.changeHandler}
                                    value={orderStatus}
                                >
                                    <option value=''>All</option>
                                    <option value='Accepted'>Accepted</option>
                                    <option value='Pending'>Started</option>
                                    <option value='Completed'>Completed</option>
                                    <option value='Closed'>Closed (Invoice)</option>
                                    <option value='Cancelled'>Cancelled</option>
                                    <option value='Dispatched'>Dispatched</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <Button
                                className='alignDown mb-3'
                                variant='primary'
                                onClick={this.displayOrders}
                            >
                            Display
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <ColoredLine color='grey' />
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No</th>
                                        <th>Store</th>
                                        <th>Customer</th>
                                        <th>Product</th>
                                        <th>Status</th>
                                        <th>Note</th>
                                        <th>Year-Week</th>
                                        <th>Quantity</th>
                                        <th>Inserted Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.orders.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.orders.map((order, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    {order.orderNumber}
                                                </td>
                                                <td>{order.storeName}</td>
                                                <td>{order.customerName}</td>
                                                <td>{order.productName}</td>
                                                <td>{order.dispatchStatus == 'Dispatched' ? 'Dispatched' : order.status}</td>
                                                <td>{order.remark}</td>
                                                <td>
                                                    {order.year}-{order.weekNumber}
                                                </td>
                                                <td>{order.quantity}</td>
                                                <td>{order.insertedDate}</td>
                                                <td>{order.status=='Pending' && userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer'?(<Link to='/order'><Button size="sm">
                                                    Accept Order
                                                </Button></Link>):(<div></div>)}
                                                {order.status=='Completed' && userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer'?(<Link to={{
                                                    pathname: '/addInvoice',
                                                    state: { id: order.orderId }
                                                }}><Button size="sm">
                                                Close Order
                                                    </Button></Link>):(<span></span>)}
                                                    {order.dispatchStatus=='Dispatched'?(
                                                        <Button
                                                        size="sm"
                                                        variant='danger'
                                                        className='ml-2'
                                                        onClick={this.openModal}
                                                        >
                                                            Track Order
                                                        </Button>
                                                    ):(<span></span>)}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}
