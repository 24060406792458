import React, { Component } from 'react';
import './materialInventory.css';
import {
    Row,
    Table,
    Button,
    Card,
    Col,
    Breadcrumb,
    Container,
    Spinner
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import invoiceLogo from '../../Images/blitzer.png';
import MaterialService from '../../Services/MaterialService';
export default class MaterialInventory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            materials: []
        };
    }
    componentDidMount () {
        console.log('Mount');
        MaterialService.getAllMaterialsWithoutPagination().then(res => {
            this.setState({ materials: res.data.materials ||[]});
        });
    }
    render () {
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainMaterial'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Material Inventory Report</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/storeInventory'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Material Iventory Report</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Row>
                    <Col lg={3} md={4} sm={6} xs={8}>
                        <img
                            src={invoiceLogo}
                            width='100%'
                            height='auto'
                            className='d-inline-block align-top'
                            alt='SG'
                        ></img>
                    </Col>
                    <Col lg={9} md={8} sm={6} xs={4}>
                        <div className="reportDate">Report Date: {new Date().toDateString()} </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='customerCard mt-2'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Category</th>
                                        <th>Material Name</th>
                                        <th>UOM</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.materials.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.materials.map((material, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>{material.categoryName}</td>
                                                <td>{material.materialName}</td>
                                                <td>{material.uom}</td>
                                                <td>{material.quantity}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}
