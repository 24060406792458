import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './materialProduct.css';
import MaterialService from '../../../Services/MaterialService';

export default class UpdateMaterialProduct extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            material: {
                materialName: String,
                categoryId: String,
                uomId: String,
                userId: 1,
                quantity: 20
            },
            uoms:[]
        };
    }
    componentDidMount () {
        console.log('Mount');
        MaterialService.getAllUoms().then(res => {
            this.setState({ uoms: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        let id = this.state.id;
        console.log(id);
        MaterialService.getSingleMaterial(id).then(res => {
            this.setState({ material: res.data ||[]});
        });
    }
  changeHandler = e => {
      let material = this.state.material;
      material[e.target.name] = e.target.value;
      this.setState({ material }, () => console.log(this.state.material));

  }

  submitMaterial = e => {
      e.preventDefault();
      let id = this.state.id;
      let material = this.state.material;
      console.log(material);
      MaterialService.updateMaterial(id,material)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/materialProduct');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { materialName, categoryId, uomId } = this.state.material;
      let {uoms}=this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainArea'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Update Material Product</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Material</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/materialProduct'>Material Product</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Material Product</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Row>
                  <Card className='customerCard'>
                      <Card.Header className='text-center'>
                          <Card.Title>
                              <h4>Update Material Product</h4>
                          </Card.Title>
                      </Card.Header>
                      <Card.Body>
                          <Form>
                              <Row>
                                  <Col lg={4} md={6} sm={6} xs={12}>
                                      <Form.Group controlId='materialName'>
                                          <Form.Label>Material Name </Form.Label>
                                          <Form.Control
                                              type='text'
                                              name='materialName'
                                              onChange={this.changeHandler}
                                              value={materialName}
                                              placeholder='Material Name'
                                          ></Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col lg={4} md={6} sm={6} xs={12}>
                                      <Form.Group controlId='categoryId'>
                                          <Form.Label>Category Name </Form.Label>
                                          <Form.Control
                                              type='text'
                                              name='categoryId'
                                              onChange={this.changeHandler}
                                              value={categoryId}
                                              placeholder='Category Name'
                                          ></Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col lg={4} md={6} sm={6} xs={12}>
                                      <Form.Group controlId='uomId'>
                                          <Form.Label>UOM</Form.Label>
                                          <Form.Control
                                              as='select'
                                              name='uomId'
                                              onChange={this.changeHandler}
                                              value={uomId}
                                          >
                                              {uoms.length === 0 ? (
                                                  <option>Select UOM</option>
                                              ) : (
                                                  uoms.map((uom, idx) => (
                                                      <option key={idx} value={uom.uomId}>
                                                          {uom.uom}
                                                      </option>
                                                  ))
                                              )}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                              </Row>
                              <Row className='centerHorizantalButton'>
                                  <Button
                                      className='mt-3 mr-3'
                                      variant='success'
                                      type='submit'
                                      onClick={this.submitMaterial}
                                  >
                    Submit
                                  </Button>
                                  <Link to='/materialProduct'>
                                      <Button className='mt-3' variant='light'>
                      Back
                                      </Button>
                                  </Link>
                              </Row>
                          </Form>
                      </Card.Body>
                  </Card>
              </Row>
          </Container>
      );
  }
}
