import React, { Component } from "react";
import "./store.css";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Container,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import MaterialOrder from "../../Services/MaterialOrder";
import Pagination from "react-js-pagination";

export default class StoreInventoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      closedStores: [],
      closedCurrentPage: 0,
      closedTotalPages: null,
      closedTotalItems: null,
      id: String,
      rejectRemark: String,
      acceptRemark: String,
      quantity: Number,
      currentPage: 0,
      totalPages: null,
      totalItems: null,
    };
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ closedCurrentPage: page });
    MaterialOrder.getAllOpenStore(page).then((res) => {
      this.setState({ closedStores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ closedTotalPages: totalPages });
      this.setState({ closedTotalItems: totalItems });
    });
  }
  handleclosedPageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    MaterialOrder.getAllClosedStore(page).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2 closed");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
  }
  acceptOrder = (e) => {
    let id = this.state.id;
    let order = {
      remark: this.state.acceptRemark,
      suppliedQuantity: this.state.quantity,
    };
    MaterialOrder.approveStoreOrder(order, id).then((res) => {
      console.log(res.data);
      this.setState({ openAcceptModal: false });
      let activePage = this.state.currentPage;
      MaterialOrder.getAllOpenStore(activePage).then((res) => {
        this.setState({ stores: res.data.storeOrders || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;
        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
      });
      let closedactivePage = this.state.closedCurrentPage;
      MaterialOrder.getAllClosedStore(closedactivePage).then((res) => {
        this.setState({ closedStores: res.data.storeOrders || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ closedTotalPages: totalPages });
        this.setState({ closedTotalItems: totalItems });
      });
    });
  };
  rejectOrder = (e) => {
    let id = this.state.id;
    let order = {
      remark: this.state.rejectRemark,
    };
    MaterialOrder.rejectStoreOrder(order, id).then((res) => {
      console.log(res.data);
      this.setState({ openModal: false });
    });
  };
  componentDidMount() {
    console.log("Mount");
    let activePage = this.state.currentPage;
    MaterialOrder.getAllOpenStore(activePage).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
    let closedactivePage = this.state.closedCurrentPage;
    MaterialOrder.getAllClosedStore(closedactivePage).then((res) => {
      this.setState({ closedStores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ closedTotalPages: totalPages });
      this.setState({ closedTotalItems: totalItems });
    });
  }
  openModal = (id) => {
    this.setState({ openModal: true });
    this.setState({ id: id });
    console.log(id);
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };
  openAcceptModal = (id) => {
    this.setState({ openAcceptModal: true });
    this.setState({ id: id });
    console.log(id);
  };

  closeAcceptModal = () => {
    this.setState({ openAcceptModal: false });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  render() {
    let { acceptRemark, rejectRemark, quantity } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    return (
      <Container className="mainStore">
        <Row className="breadcrum">
          <Col>
            <h4>Stores</h4>
          </Col>
          <Col>
            <Breadcrumb className="alignRight">
              <Breadcrumb.Item>
                <Link to="/storeInventory">
                  <FaHome />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Store</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <ColoredLine color="grey" />
        <Tabs defaultActiveKey="openOrder" id="controlled-tab-example">
          <Tab eventKey="openOrder" title="Open Order">
            <Container className="mainStore">
              <Row>
                <Card className="customerCard">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Material Name</th>
                        <th>Total Inventory</th>
                        <th>Quantity</th>
                        <th>Area Name</th>
                        <th>Operator Name</th>
                        <th>Request Date</th>
                        <th className="actionWidth">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.stores.length === 0 ? (
                        <div></div>
                      ) : (
                        this.state.stores.map((store, idx) => (
                          <tr key={idx}>
                            <td>{store.materialName}</td>
                            <td>{store.totalInventory}</td>
                            <td>{store.orderQuantity}</td>
                            <td>{store.areaName}</td>
                            <td>{store.operatorName}</td>
                            <td>{store.insertedDate}</td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Accept
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="success"
                                  className="ml-2"
                                  onClick={() =>
                                    this.openAcceptModal(store.storeOrderId)
                                  }
                                >
                                  <MdDone />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Reject
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  className="ml-2"
                                  onClick={() =>
                                    this.openModal(store.storeOrderId)
                                  }
                                >
                                  <MdDeleteForever />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <Modal
                    className="textCenter"
                    show={this.state.openModal}
                    onHide={this.closeModal}
                  >
                    <Modal.Header closeButton>Reject Order</Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group controlId="rejectRemark">
                          <Form.Label>Remark </Form.Label>
                          <Form.Control
                            type="text"
                            name="rejectRemark"
                            onChange={this.changeHandler}
                            value={rejectRemark}
                            placeholder="Remark"
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.closeModal}>
                        Close
                      </Button>
                      <Button variant="danger" onClick={this.rejectOrder}>
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    className="textCenter"
                    show={this.state.openAcceptModal}
                    onHide={this.closeAcceptModal}
                  >
                    <Modal.Header closeButton>Accept Order</Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group controlId="quantity">
                          <Form.Label>Quantity </Form.Label>
                          <Form.Control
                            type="number"
                            name="quantity"
                            onChange={this.changeHandler}
                            value={quantity}
                            placeholder="Quantity"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="acceptRemark">
                          <Form.Label>Remark </Form.Label>
                          <Form.Control
                            type="text"
                            name="acceptRemark"
                            onChange={this.changeHandler}
                            value={acceptRemark}
                            placeholder="Remark"
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={this.closeAcceptModal}
                      >
                        Close
                      </Button>
                      <Button variant="success" onClick={this.acceptOrder}>
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Card>
              </Row>
              <div className="d-flex justify-content-center mt-2 mb-2">
                <Pagination
                  hideNavigation
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalItems}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="btn btn-light"
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </Container>
          </Tab>
          <Tab eventKey="closedOrder" title="Closed Order">
            <Container className="mainStore">
              <Row>
                <Card className="customerCard">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Material Name</th>
                        <th>Requested Quantity</th>
                        <th>Area Name</th>
                        <th>Operator Name</th>
                        <th>Request Date</th>
                        <th>Provided Quantity</th>
                        <th>Close Date</th>
                        <th>Remark</th>
                        <th>Is Rejected</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.closedStores.length === 0 ? (
                        <div></div>
                      ) : (
                        this.state.closedStores.map((store, idx) => (
                          <tr key={idx}>
                            <td>{store.materialName}</td>
                            <td>{store.orderQuantity}</td>
                            <td></td>
                            <td>{store.operatorName}</td>
                            <td>{store.requestedDate}</td>
                            <td>{store.suppliedQuantity}</td>
                            <td>{store.closedDate}</td>
                            <td>{store.remark}</td>
                            <td>{store.isRejected ? "Yes" : "No"}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Row>
              <div className="d-flex justify-content-center mt-2 mb-2">
                <Pagination
                  hideNavigation
                  activePage={this.state.closedactivePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.closedTotalItems}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="btn btn-light"
                  onChange={this.handleclosedPageChange.bind(this)}
                />
              </div>
            </Container>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}
