import React from "react";
import "./App.css";
import AuthenticatedRoute from "./Services/AuthenticatedRoute";
import Home from "./Components/Pages/HomePage/HomeComponent";
import Logout from "./Components/Authentication/LogoutComponent";
import Login from "./Components/Authentication/LoginComponent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Customer from "./Components/Profiles/Customers/CustomerComponent";
import AddCustomer from "./Components/Profiles/Customers/AddCustomer";
import UpdateCustomer from "./Components/Profiles/Customers/UpdateCustomer";
import AddCustomerExcel from "./Components/Profiles/Customers/AddCustomerExcel";
import AddStore from "./Components/Profiles/Stores/AddStore";
import AddSupplier from "./Components/Profiles/Supplier/AddSupplier";
import UpdateSupplier from "./Components/Profiles/Supplier/UpdateSupplier";
import AddUser from "./Components/Profiles/Users/AddUser";
import UpdateUser from "./Components/Profiles/Users/UpdateUser";
import Footer from "./Components/Site/Footer/MainFooter";
import SupplierComponent from "./Components/Profiles/Supplier/SupplierComponent";
import StoreComponent from "./Components/Profiles/Stores/StoreComponent";
import UpdateStore from "./Components/Profiles/Stores/UpdateStore";
import AddSupplierExcel from "./Components/Profiles/Supplier/AddSupplierExcel";
import UserComponent from "./Components/Profiles/Users/UserComponent";
import ZoneComponent from "./Components/Configuration/Zone/ZoneComponent";
import AddZone from "./Components/Configuration/Zone/AddZone";
import UpdateZone from "./Components/Configuration/Zone/UpdateZone";
import CategoryComponent from "./Components/Configuration/Category/CategoryComponent";
import ProductComponent from "./Components/Configuration/Product/ProductComponent";
import AddProductExcel from "./Components/Configuration/Product/AddProductExcel";
import AddProduct from "./Components/Configuration/Product/AddProduct";
import AddCategory from "./Components/Configuration/Category/AddCategory";
import UpdateCategory from "./Components/Configuration/Category/UpdateCategory";
import SubCategoryComponent from "./Components/Configuration/SubCategory/SubCategoryComponent";
import AddSubCategory from "./Components/Configuration/SubCategory/AddSubCategory";
import UpdateSubCategory from "./Components/Configuration/SubCategory/UpdateSubCategory";
import AreaComponent from "./Components/Configuration/Area/AreaComponent";
import AddArea from "./Components/Configuration/Area/AddArea";
import UpdateArea from "./Components/Configuration/Area/UpdateArea";
import MaterialProductComponent from "./Components/MaterialManagement/Product/MaterialProductComponent";
import AddMaterialProductExcel from "./Components/MaterialManagement/Product/AddMaterialProductExcel";
import AddMaterialProduct from "./Components/MaterialManagement/Product/AddMaterialProduct";
import UpdateMaterialProduct from "./Components/MaterialManagement/Product/UpdateMaterialProduct";
import SupplierProductComponent from "./Components/MaterialManagement/SupplierProduct/SupplierProductComponent";
import AddSupplierProductExcel from "./Components/MaterialManagement/SupplierProduct/AddSupplierProductExcel";
import OperatorAllocation from "./Components/Configuration/OperatorAllocation/OperatorAllocation";
import TaxComponent from "./Components/Configuration/Tax/TaxComponent";
import UpdateTax from "./Components/Configuration/Tax/UpdateTax";
import RackComponent from "./Components/Configuration/Rack/RackComponent";
import AddRack from "./Components/Configuration/Rack/AddRack";
import UpdateRack from "./Components/Configuration/Rack/UpdateRack";
import AddRackExcel from "./Components/Configuration/Rack/AddRackExcel";
import RackDashboard from "./Components/Configuration/Rack/RackDashboard";
import AddOrder from "./Components/OrderManagement/AddOrder";
import DispatchComponent from "./Components/Dispatch/DipatchComponent";
import InvoiceComponent from "./Components/Invoice/InvoiceComponent";
import AddOrderExcel from "./Components/OrderManagement/AddOrderExcel";
import UpdateProduct from "./Components/Configuration/Product/UpdateProduct";
import OrderComponent from "./Components/OrderManagement/OrderComponent";
import OrderStatus from "./Components/OrderManagement/OrderStatus";
import StoreInventoryComponent from "./Components/StoreInventory/StoreInventoryComponent";
import MaterialInventory from "./Components/MaterialInventory/MaterialInventory";
import MaterialRequest from "./Components/Material Request/MaterialRequest";
import ShopComponent from "./Components/Shop/ShopComponent";
import PurchaseOrder from "./Components/MaterialManagement/Purchase Order/PurchaseOrder";
import AddPurchaseOrder from "./Components/MaterialManagement/Purchase Order/AddPurchaseOrder";
import EditPurchaseOrder from "./Components/MaterialManagement/Purchase Order/EditPurchaseOrder";
import Error404 from "./Components/Pages/Error404";
import AddInvoice from "./Components/Invoice/AddInvoice";
import RecipeComponent from "./Components/Configuration/Recipe/RecipeComponent";
import AddNewRecipe from "./Components/Configuration/Recipe/AddNewRecipe";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <AuthenticatedRoute path="/home" exact component={Home} />
        <AuthenticatedRoute path="/customer" exact component={Customer} />
        <AuthenticatedRoute path="/addCustomer" exact component={AddCustomer} />
        <AuthenticatedRoute
          path="/updateCustomer"
          exact
          component={UpdateCustomer}
        />
        <AuthenticatedRoute
          path="/addCustomerExcel"
          exact
          component={AddCustomerExcel}
        />
        <AuthenticatedRoute
          path="/supplier"
          exact
          component={SupplierComponent}
        />
        <AuthenticatedRoute path="/addSupplier" exact component={AddSupplier} />
        <AuthenticatedRoute
          path="/updateSupplier"
          exact
          component={UpdateSupplier}
        />
        <AuthenticatedRoute
          path="/addSupplierExcel"
          exact
          component={AddSupplierExcel}
        />
        <AuthenticatedRoute path="/user" exact component={UserComponent} />
        <AuthenticatedRoute path="/addUser" exact component={AddUser} />
        <AuthenticatedRoute path="/updateUser" exact component={UpdateUser} />
        <AuthenticatedRoute path="/store" exact component={StoreComponent} />
        <AuthenticatedRoute path="/addStore" exact component={AddStore} />
        <AuthenticatedRoute path="/updateStore" exact component={UpdateStore} />
        <AuthenticatedRoute path="/zone" exact component={ZoneComponent} />
        <AuthenticatedRoute path="/addZone" exact component={AddZone} />
        <AuthenticatedRoute path="/updateZone" exact component={UpdateZone} />
        <AuthenticatedRoute
          path="/category"
          exact
          component={CategoryComponent}
        />
        <AuthenticatedRoute
          path="/updateCategory"
          exact
          component={UpdateCategory}
        />
        <AuthenticatedRoute
          path="/subCategory"
          exact
          component={SubCategoryComponent}
        />
        <AuthenticatedRoute path="/addCategory" exact component={AddCategory} />
        <AuthenticatedRoute
          path="/addSubCategory"
          exact
          component={AddSubCategory}
        />
        <AuthenticatedRoute
          path="/updateSubCategory"
          exact
          component={UpdateSubCategory}
        />
        <AuthenticatedRoute
          path="/product"
          exact
          component={ProductComponent}
        />
        <AuthenticatedRoute path="/addProduct" exact component={AddProduct} />
        <AuthenticatedRoute
          path="/updateProduct"
          exact
          component={UpdateProduct}
        />
        <AuthenticatedRoute
          path="/productAddExcel"
          exact
          component={AddProductExcel}
        />
        <AuthenticatedRoute path="/area" exact component={AreaComponent} />
        <AuthenticatedRoute path="/addArea" exact component={AddArea} />
        <AuthenticatedRoute path="/updateArea" exact component={UpdateArea} />
        <AuthenticatedRoute
          path="/materialProduct"
          exact
          component={MaterialProductComponent}
        />
        <AuthenticatedRoute
          path="/addMaterialProductExcel"
          exact
          component={AddMaterialProductExcel}
        />
        <AuthenticatedRoute
          path="/addMaterialProduct"
          exact
          component={AddMaterialProduct}
        />
        <AuthenticatedRoute
          path="/updateMaterialProduct"
          exact
          component={UpdateMaterialProduct}
        />
        <AuthenticatedRoute
          path="/supplierProduct"
          exact
          component={SupplierProductComponent}
        />
        <AuthenticatedRoute
          path="/addSupplierProductExcel"
          exact
          component={AddSupplierProductExcel}
        />
        <AuthenticatedRoute
          path="/operatorAllocation"
          exact
          component={OperatorAllocation}
        />
        <AuthenticatedRoute path="/tax" exact component={TaxComponent} />
        <AuthenticatedRoute path="/updateTax" exact component={UpdateTax} />
        <AuthenticatedRoute path="/rack" exact component={RackComponent} />
        <AuthenticatedRoute path="/addRack" exact component={AddRack} />
        <AuthenticatedRoute path="/updateRack" exact component={UpdateRack} />
        <AuthenticatedRoute
          path="/addRackExcel"
          exact
          component={AddRackExcel}
        />
        <AuthenticatedRoute
          path="/rackDashboard"
          exact
          component={RackDashboard}
        />
        <AuthenticatedRoute path="/addOrder" exact component={AddOrder} />
        <AuthenticatedRoute
          path="/addOrderExcel"
          exact
          component={AddOrderExcel}
        />
        <AuthenticatedRoute path="/order" exact component={OrderComponent} />
        <AuthenticatedRoute path="/orderStatus" exact component={OrderStatus} />
        <AuthenticatedRoute
          path="/purchaseOrder"
          exact
          component={PurchaseOrder}
        />
        <AuthenticatedRoute
          path="/addPurchaseOrder"
          exact
          component={AddPurchaseOrder}
        />
        <AuthenticatedRoute
          path="/editPurchaseOrder"
          exact
          component={EditPurchaseOrder}
        />
        <AuthenticatedRoute
          path="/dispatch"
          exact
          component={DispatchComponent}
        />
        <AuthenticatedRoute
          path="/invoice"
          exact
          component={InvoiceComponent}
        />
        <AuthenticatedRoute path="/addInvoice" exact component={AddInvoice} />
        <AuthenticatedRoute
          path="/storeInventory"
          exact
          component={StoreInventoryComponent}
        />
        <AuthenticatedRoute
          path="/materialInventory"
          exact
          component={MaterialInventory}
        />
        <AuthenticatedRoute
          path="/materialRequest"
          exact
          component={MaterialRequest}
        />
        <AuthenticatedRoute path="/shop" exact component={ShopComponent} />
        <AuthenticatedRoute path="/logout" exact component={Logout} />
        <AuthenticatedRoute path="/recipe" exact component={RecipeComponent} />
        <AuthenticatedRoute path="/addrecipe" exact component={AddNewRecipe} />
        <AuthenticatedRoute component={Error404} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
