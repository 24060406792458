import React, { Component } from 'react';
import {
    Row,
    Col,
    Tabs,
    Tab,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './dispatch.css';
import DispatchedOrders from './DispatchedOrders';
import NonDipatchedOrders from './NonDipatchedOrders';
import AuthenticationService from '../../Services/AuthenticationService';
export default class DipatchComponent extends Component {
    
    render () {
        let userRole = AuthenticationService.getRole();
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainDispatch'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Dispatch</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/shop'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Dispatch</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                {userRole=='ROLE_Administrator' &&(
                    <Tabs defaultActiveKey="dispatchedOrder" 
                        id="controlled-tab-example">
                        <Tab eventKey="dispatchedOrder" title="Dispatched Order">
                            <DispatchedOrders/> 
                        </Tab>
                        <Tab eventKey="nonDispatchedOrder" title="Non Dispatched Order">
                            <NonDipatchedOrders/>
                        </Tab>
                    </Tabs>
                )}
                {userRole=='ROLE_Operator' &&(
                    <NonDipatchedOrders/>
                )}
            </Container>
        );
    }
}
