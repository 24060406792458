import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    OverlayTrigger,
    Tooltip,
    Breadcrumb
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import './supplier.css';
import { MdDone } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import supplierService from '../../../Services/SupplierService';

export default class SupplierComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            suppliers: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }

    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        supplierService.getAllSupplier(page).then(res => {
            this.setState({ suppliers: res.data.suppliers ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    enable (id) {
        supplierService.enable(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            supplierService.getAllSupplier(page).then(res => {
                this.setState({ suppliers: res.data.suppliers ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    disable (id) {
        supplierService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            supplierService.getAllSupplier(page).then(res => {
                this.setState({ suppliers: res.data.suppliers ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        supplierService.getAllSupplier(activePage).then(res => {
            this.setState({ suppliers: res.data.suppliers ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }

    render () {
        let { currentPage } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainSupplier'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Supplier</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Profiles</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Supplier</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addSupplierExcel'>
                        <Button className='mr-3' variant='light'>
              Add Excel
                        </Button>
                    </Link>

                    <Link to='/addSupplier'>
                        <Button variant='light'>Add Supplier</Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Supplier Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Inserted Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.suppliers.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.suppliers.map((supplier, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1 + currentPage * 10}</td>
                                                <td>{supplier.supplierName}</td>
                                                <td>{supplier.emailAddress}</td>
                                                <td>{supplier.phoneNumber}</td>
                                                <td>{supplier.insertedDate}</td>
                                                <td>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}> 
                                                        <Link
                                                            to={{
                                                                pathname: '/updateSupplier',
                                                                state: { id: supplier.supplierId }
                                                            }}
                                                        >
                                                            <Button variant='success'>
                                                                <BiEdit />
                                                            </Button>
                                                        </Link>
                                                    </OverlayTrigger>
                                                    {supplier.isDeleted ? (
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Enable</Tooltip>}>        
                                                            <Button
                                                                variant='success'
                                                                className='ml-2'
                                                                onClick={() => {
                                                                    this.enable(supplier.supplierId);
                                                                }}
                                                            >
                                                                <MdDone />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    ) : (<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Disable</Tooltip>}>
                                                        <Button
                                                            variant='danger'
                                                            className='ml-2'
                                                            onClick={() => {
                                                                this.disable(supplier.supplierId);
                                                            }}
                                                        >
                                                            <MdDeleteForever />
                                                        </Button>
                                                    </OverlayTrigger>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
                <NotificationContainer />
            </Container>
        );
    }
}
