import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './rack.css';
import RackService from '../../../Services/RackService';

export default class UpdateRack extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            rack: {
                rackName: String,
                barcode: String
            }
        };
    }
    componentDidMount () {
        let id = this.state.id;
        RackService.getSingleRack(id).then(res => {
            this.setState({ rack: res.data ||[]});
        });
    }

  changeHandler = e => {
      let rack = this.state.rack;
      rack[e.target.name] = e.target.value;
      this.setState({ rack }, () => console.log(this.state.rack));
  }

  updateRack = e => {
      e.preventDefault();
      let id = this.state.id;
      let rack = this.state.rack;
      RackService
          .updateRack(id, rack)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/rack');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { rackName, barcode} = this.state.rack;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainRack mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Rack</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/rack'>Rack</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Rack</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Update Rack</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='rackName'>
                                      <Form.Label>Rack Name </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='rackName'
                                          onChange={this.changeHandler}
                                          value={rackName}
                                          placeholder='Rack Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='barcode'>
                                      <Form.Label>Barcode </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='barcode'
                                          onChange={this.changeHandler}
                                          value={barcode}
                                          placeholder='Barcode'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.updateRack}
                              >
                  Submit
                              </Button>
                              <Link to='/rack'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
