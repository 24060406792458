import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Tooltip,
    Modal,
    Form,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { RiBillFill } from 'react-icons/ri';
import Pagination from 'react-js-pagination';
import MaterialOrder from '../../../Services/MaterialOrder';
import './purchase.css';
export default class PurchaseOrder extends Component {
    constructor (props) {
        super(props);
        this.state = {
            id:String,
            invoiceNumber:String,
            invoiceDate:Date,
            totalCost:Number,
            purchaseOrders: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        console.log(activePage);
        MaterialOrder.getAllPurchases(activePage).then(res => {
            this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    delete=e=>{
        e.preventDefault();
        let activePage = this.state.currentPage;
        MaterialOrder.deletePurchaseOrder(this.state.id).then(res => {
            this.closeModal();
            MaterialOrder.getAllPurchases(activePage).then(res => {
                this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
    
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        MaterialOrder.getAllPurchases(activePage).then(res => {
            this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    openModal = (id) => {
        this.setState({ openModal: true ,id:id});
        console.log(id);
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    openBillModal = (id) => {
        this.setState({ openBillModal: true ,id:id});
        console.log(id);
    }
    openBillClosedModal= (id,totalCost,invoiceDate,invoiceNumber) => {
        this.setState({ openBillModal: true ,id:id,totalCost:totalCost,invoiceDate:invoiceDate,invoiceNumber:invoiceNumber});
    }
    closeBillModal = () => {
        this.setState({ openBillModal: false,invoiceNumber:'',invoiceDate:'',totalCost:'' });
    }
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(this.state);
        });
    }
    submitBill =(e)=> {
        let id =this.state.id;
        let bill={
            invoiceNumber:this.state.invoiceNumber,
            invoiceDate:this.state.invoiceDate,
            totalCost: this.state.totalCost
        };
        let activePage = this.state.currentPage;
        MaterialOrder.payBill(id,bill).then(res => {
            this.setState({ openBillModal: false });
            MaterialOrder.getAllPurchases(activePage).then(res => {
                this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
    
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    render() {
        let { currentPage,invoiceNumber,invoiceDate,totalCost } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainPurchase'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Purchase Orders</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Material</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Purchase Orders</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addPurchaseOrder'>
                        <Button variant='light'>Add Purchase Order</Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>PO Number</th>
                                        <th>Supplier Name</th>
                                        <th>Phone Number</th>
                                        <th>Status</th>
                                        <th>Total Cost</th>
                                        <th>PO Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.purchaseOrders.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.purchaseOrders.map((purchase, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1 + currentPage * 10}</td>
                                                <td>{purchase.purchaseOrderNumber}</td>
                                                <td>{purchase.supplierName}</td>
                                                <td>{purchase.supplierPhoneNumber}</td>
                                                <td>{purchase.purchaseOrderStatus}</td>
                                                <td>{purchase.currency} {purchase.totalCost}</td>
                                                <td>{purchase.purchaseOrderName}</td>
                                                <td>
                                                    {purchase.purchaseOrderStatus=='OPEN' ? (
                                                        <>  
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bills</Tooltip>}>
                                                                <Button variant='secondary'
                                                                    onClick={() => {
                                                                        this.openBillModal(purchase.purchaseOrderId);
                                                                    }}>
                                                                    <RiBillFill />
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                <Button
                                                                    variant='danger'
                                                                    className='ml-2 mr-2'
                                                                    onClick={() => {
                                                                        this.openModal(purchase.purchaseOrderId);
                                                                    }}
                                                                >
                                                                    <MdDeleteForever />
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/editPurchaseOrder',
                                                                        state: { id: purchase.purchaseOrderId }
                                                                    }}
                                                                >
                                                                    <Button variant='success'>
                                                                        <BiEdit />
                                                                    </Button>
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </>
                                                    ) : <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bills</Tooltip>}>
                                                        <Button variant='secondary'
                                                            onClick={() => {
                                                                this.openBillClosedModal(purchase.purchaseOrderId,purchase.totalCost,
                                                                    purchase.invoiceNumber,purchase.invoiceDate);
                                                            }}>
                                                            <RiBillFill />
                                                        </Button>
                                                    </OverlayTrigger>}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                        <Modal
                            className='textCenter'
                            show={this.state.openModal}
                            onHide={this.closeModal}
                        >
                            <Modal.Header closeButton>Delete</Modal.Header>
                            <Modal.Body>Do you really want to delete?</Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={this.closeModal}>
                                    Close                                      
                                </Button>
                                <Button variant='danger' onClick={this.delete}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal> 
                        <Modal
                            className='textCenter'
                            show={this.state.openBillModal}
                            onHide={this.closeBillModal}
                        >
                            <Modal.Header closeButton>Billing Information</Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId='invoiceNumber'>
                                        <Form.Label>Invoice Number </Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='invoiceNumber'
                                            onChange={this.changeHandler}
                                            value={invoiceNumber}
                                            placeholder='Invoice Number'
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='invoiceDate'>
                                        <Form.Label>Invoice Date </Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='invoiceDate'
                                            onChange={this.changeHandler}
                                            value={invoiceDate}
                                            placeholder='Invoice Date'
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='totalCost'>
                                        <Form.Label>Total Cost </Form.Label>
                                        <Form.Control
                                            type='number'
                                            name='totalCost'
                                            onChange={this.changeHandler}
                                            value={totalCost}
                                            placeholder='Total Cost'
                                        ></Form.Control>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={this.closeBillModal}>
                                    Close                                      
                                </Button>
                                <Button variant='primary' onClick={this.submitBill}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal> 
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container>

        );
    }
}
