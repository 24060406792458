import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";

// const API_URL = 'http://localhost:8081';
//const API_URL = 'http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = 'http://35.200.212.170'; // /masterdata/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class MasterDataService {
  getAllCountries() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/masterdata/api/address/countries`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllCurrencies() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/masterdata/api/currencies`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getTax() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/masterdata/api/tax/1`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateTax(data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/masterdata/api/tax/1`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  getAllYears() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/masterdata/api/years`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllWeeks() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/masterdata/api/weeks`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  //to be deleted in future as dashboard service will be non existant
  getAllDashboardData() {
    let token = AuthorizationService.getToken();
    // return axios.get('http://localhost:8093/dashboard/api/all-stats', {
    // return axios.get(' http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com/dashboard/api/all-stats', {
    // http://35.200.212.170/dashboard/api/

    return axios.get("http://35.200.212.170/dashboard/api/all-stats", {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
}
export default new MasterDataService();
