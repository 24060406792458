import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import HeaderComponent from '../Components/Site/Header/HeaderComponent';
import AuthenticationService from './AuthenticationService';
class AuthenticatedRoute extends Component {
    render () {
        if (AuthenticationService.isUserLoggedIn()) {
            return (
                <>
                    <HeaderComponent />
                    <Route {...this.props} />
                </>
            );
        } else {
            return <Redirect to='/' />;
        }
    }
}

export default AuthenticatedRoute;
