import React, { Component } from 'react';
import {
    Card,
    Container,
    Table,
    Button,
    Breadcrumb,
    Row,
    Col,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './tax.css';
import MasterDataService from '../../../Services/MasterDataService';

export default class TaxComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            tax: {}
        };
    }
    componentDidMount () {
        console.log('Mount');
        MasterDataService.getTax().then(res => {
            this.setState({ tax: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
    }
    render () {
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        let { tax } = this.state;
        return (
            <Container className='mainTax'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>HST</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Configuration</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Tax</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Row>
                    <Col>
                        <Card className='customerCard textCenter'>
                            <Card.Body>
                                <Row className='m-3'>
                                    <Col>
                                        <h4>Tax Component</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>HST %</th>
                                                    <th>Tax Number</th>
                                                    <th>Business Number</th>
                                                    <th>Inserted Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>{tax.tax}</td>
                                                    <td>{tax.taxNumber}</td>
                                                    <td>{tax.businessNumber}</td>
                                                    <td>{tax.insertedDate}</td>
                                                    <td>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                            <Link
                                                                to={{
                                                                    pathname: '/updateTax'
                                                                }}
                                                            >
                                                                <Button variant='success'>
                                                                    <BiEdit />
                                                                </Button>
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}
