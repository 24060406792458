import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8084';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL ='http://35.200.212.170'; // /supplier/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class SupplierService {
  getAllSupplier(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/supplier/api/suppliers?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllSupplierWithoutPagination() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/supplier/api/suppliers/all-suppliers`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  enable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/supplier/api/suppliers/${id}/enable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  disable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/supplier/api/suppliers/${id}/disable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSingleSupplier(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/supplier/api/suppliers/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateSupplier(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/supplier/api/suppliers/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addSupplierExcel(formdata) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/supplier/api/suppliers/upload`, formdata, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  addSupplier(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/supplier/api/suppliers/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}
export default new SupplierService();
