import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Breadcrumb,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdDone } from 'react-icons/md';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import './user.css';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserService from '../../../Services/UsersService';

export default class UserComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            users: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        UserService.getAllUser(activePage).then(res => {
            this.setState({ users: res.data.users ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        UserService.getAllUser(page).then(res => {
            this.setState({ users: res.data.users ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    enable (id) {
        UserService.enable(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            UserService.getAllUser(page).then(res => {
                this.setState({ users: res.data.users ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    disable (id) {
        UserService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            UserService.getAllUser(page).then(res => {
                this.setState({ users: res.data.users ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    render() {
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainUser'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>User</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Profiles</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>User</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addUser'>
                        <Button variant='light'>Add User</Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Gender</th>
                                        <th>Inserted Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.users.map((user, idx) => (
                                            <tr key={idx}>
                                                <td>{idx+1}</td>
                                                <td>{user.firstName}</td>
                                                <td>{user.emailAddress}</td>
                                                <td>{user.userRole.roleName}</td>
                                                <td>{user.phoneNumber}</td>
                                                <td>{user.address.address1}</td>
                                                <td>{user.gender}</td>
                                                <td>{user.insertedDate}</td>
                                                <td>
                                                    <Link
                                                        to={{
                                                            pathname: '/updateUser',
                                                            state: { id: user.userId }
                                                        }}
                                                    >
                                                        <Button variant='success'>
                                                            <BiEdit />
                                                        </Button>
                                                    </Link>
                                                    {user.isDeleted ? (
                                                        <Button
                                                            variant='success'
                                                            className='ml-2'
                                                            onClick={() => {
                                                                this.enable(user.userId);
                                                            }}
                                                        >
                                                            <MdDone />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant='danger'
                                                            className='ml-2'
                                                            onClick={() => {
                                                                this.disable(user.userId);
                                                            }}
                                                        >
                                                            <MdDeleteForever />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
                <NotificationContainer />
            </Container>
        );
    }
}
