import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import customerService from '../../../Services/CustomerService';
import masterDataService from '../../../Services/MasterDataService';
import './store.css';
export default class UpdateStore extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            countries: [],
            corporates: [],
            customers: [],
            store: {
                corporateName: String,
                customerId: Number,
                customerName: String,
                storeName: String,
                storeNumber: String,
                emailAddress: String,
                phoneNumber: String,
                addresses: [
                    {
                        address1: String,
                        address2: String,
                        city: String,
                        region: String,
                        province: String,
                        country: String
                    }
                ]
            },
            formErrors: {
                corporateName: '',
                customerName: ''
            },
            errorStatus: false
        };
    }
    componentDidMount () {
        let id = this.state.id;
        console.log(id);
        customerService.getSingleStore(id).then(res => {
            console.log(res.data);
            this.setState({ store: res.data ||[]});
            let corporateName = this.state.store.corporateName;
            console.log(corporateName);
            customerService.getCustomerbyCorporateName(corporateName).then(res => {
                this.setState({ customers: res.data.customers ||[]});
            });
        });
        masterDataService.getAllCountries().then(res => {
            this.setState({ countries: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        customerService.getAllCorporates().then(res => {
            this.setState({ corporates: res.data.corporates ||[]});
        });
    }
    onChange (e) {
        console.log('inside if');
        let addresses = this.state.store.addresses[0];
        console.log(e.target.name);
        addresses[e.target.name] = e.target.value;
        this.setState({ addresses }, () => console.log(this.state.store.addresses));
    }

  changeHandler = e => {
      let store = this.state.store;
      console.log(e.target.name);
      store[e.target.name] = e.target.value;
      this.setState({ store }, () => console.log(this.state.store));
      switch (e.target.name) {
      case 'corporateName':
          let name = e.target.value;
          console.log(name);
          customerService.getCustomerbyCorporateName(name).then(res => {
              this.setState({ customers: res.data.customers ||[]});
          });
          break;
      default:
       
      }
  }

  submitStore = e => {
      e.preventDefault();
      let id = this.state.id;
      let store = this.state.store;
      console.log('Inside form submission');
      customerService
          .updateStore(id, store)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/store');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let {
          corporateName,
          customerId,
          storeName,
          storeNumber,
          emailAddress,
          phoneNumber,
          addresses
      } = this.state.store;
      let { corporates, countries, customers } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Store</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Profiles</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/Store'>Store</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Store</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Update Store</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='corporateName'>
                                      <Form.Label>Corporate Name </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='corporateName'
                                          onChange={this.changeHandler}
                                          value={corporateName}
                                      >
                                          <option>Select Corporate</option>
                                          {corporates.map((corporate, idx) => (
                                              <option key={idx} value={corporate.corporateName}>
                                                  {corporate.corporateName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='customerId'>
                                      <Form.Label>Customer Name </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='customerId'
                                          onChange={this.changeHandler}
                                          value={customerId}
                                      >
                                          <option>Select Customers</option>
                                          {customers.map((customer, idx) => (
                                              <option key={idx} value={customer.customerId}>
                                                  {customer.customerName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='storeName'>
                                      <Form.Label>Store Name </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='storeName'
                                          onChange={this.changeHandler}
                                          value={storeName}
                                          placeholder='Store Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='storeNumber'>
                                      <Form.Label>Store Number </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='storeNumber'
                                          onChange={this.changeHandler}
                                          value={storeNumber}
                                          placeholder='Store Number'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='emailAddress'>
                                      <Form.Label>Email Address </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='emailAddress'
                                          onChange={this.changeHandler}
                                          value={emailAddress}
                                          placeholder='Email Address'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='phoneNumber'>
                                      <Form.Label>Phone Number</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='phoneNumber'
                                          onChange={this.changeHandler}
                                          value={phoneNumber}
                                          placeholder='Phone Number'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col>
                                  <Form.Group controlId='addresses.address1'>
                                      <Form.Label>Address</Form.Label>
                                      <Form.Control
                                          type='textarea'
                                          rows='4'
                                          name='address1'
                                          onChange={e => this.onChange(e)}
                                          value={addresses[0].address1}
                                          placeholder='Enter address ...'
                                      />
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.country'>
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='country'
                                          onChange={e => this.onChange(e)}
                                          value={addresses[0].country}
                                      >
                                          <option>Select Country</option>
                                          {countries.map((country, idx) => (
                                              <option key={idx} value={country.value}>
                                                  {country.value}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.city'>
                                      <Form.Label>City</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='city'
                                          onChange={e => this.onChange(e)}
                                          value={addresses[0].city}
                                          placeholder='City Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.province'>
                                      <Form.Label>Province</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='province'
                                          onChange={e => this.onChange(e)}
                                          value={addresses[0].province}
                                          placeholder='Province Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.region'>
                                      <Form.Label>Region</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='region'
                                          onChange={e => this.onChange(e)}
                                          value={addresses[0].region}
                                          placeholder='Region Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.submitStore}
                              >
                  Submit
                              </Button>
                              <Link to='/store'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
