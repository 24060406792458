import React, { Component } from "react";
import { Row, Table, Col, Card, Container, Breadcrumb,Form } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./invoice.css";
import CustomerOrderService from "../../Services/CustomerOrderService";
import { BsBoxArrowInDown } from "react-icons/bs";
const loggedIn = sessionStorage.getItem('loggedInUser');
import AuthenticationService from '../../Services/AuthenticationService';

export default class InvoiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corporates: [],
      corporateId: Number,
      invoices: [],
    };
  }
  componentDidMount() {
    // CustomerOrderService.getAllInvoices().then((res) => {
    //   this.setState({ invoices: res.data || [] });
    // });
    let userRole = AuthenticationService.getRole();
    if(userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer'){
      CustomerOrderService.getAllInvoices().then((res) => {
        this.setState({ invoices: res.data || [] });
      });
    }else{
      let demoRes = {
        data:{
          corporates:[
            {
              "corporateId": 1,
              "corporateName": "DEMO GREENHOUSE 1"
            },
            {
              "corporateId": 2,
              "corporateName": "DEMO GREENHOUSE 2"
            },
            {
              "corporateId": 3,
              "corporateName": "DEMO GREENHOUSE 3"
            }
          ]
        }
      }
      this.setState({ corporates: demoRes.data.corporates || [] });
    }
  }

  changeHandler = (e) => {
    let id = e.target.value;
    switch (e.target.name) {
      case "corporateId":
        console.log(id);
        CustomerOrderService.getAllInvoicesV1(id).then((res) => {
          this.setState({ invoices: res.data || [] });
        });
        break;
      default:
        break;
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  downloadFile = (id) => {
    CustomerOrderService.downloadInvoice(id).then((response) => {
      const url = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(url);
      link.download = "orderInvoice.pdf";
      link.click();
      return response.data;
    });
  };
  render() {
    let userRole = AuthenticationService.getRole();
    let {
      corporates,
      corporateId
    } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );

    return (
      <Container className="mainInvoice">
        <Row className="breadcrum">
          <Col>
            <h4>Invoice</h4>
          </Col>
          <Col>
            <Breadcrumb className="alignRight">
              <Breadcrumb.Item>
                <Link to="/home">
                  <FaHome />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/home"> Order</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Invoice</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <ColoredLine color="grey" />
        <Row>
        {userRole == 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? (
          <Col xs={12}>
          <Form.Group controlId="corporateId">
            <Form.Label>Greenhouse</Form.Label>
            <Form.Control
              as="select"
              name="corporateId"
              onChange={this.changeHandler}
              value={corporateId}
            >
              <option>Select Greenhouse</option>
              {corporates.map((corporate, idx) => (
                <option key={idx} value={corporate.corporateId}>
                  {corporate.corporateName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      ) : ('')}
          <Col>
            <Card className="customerCard">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Invoice Number</th>
                    <th>Customer Name</th>
                    <th>Store Name</th>
                    <th>Total Items</th>
                    <th>HST%</th>
                    <th>Cost</th>
                    <th>HST</th>
                    <th>Total Cost</th>
                    <th>Invoice Date</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoices.length === 0 ? (
                    <div></div>
                  ) : (
                    this.state.invoices.map((invoice, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{invoice.customerName}</td>
                        <td>{invoice.storeName}</td>
                        <td>{invoice.totalItems}</td>
                        <td>{invoice.tax}</td>
                        <td>
                          {invoice.currency} {invoice.subtotalCost}
                        </td>
                        <td>
                          {invoice.currency} {invoice.taxCost}
                        </td>
                        <td>
                          {invoice.currency} {invoice.totalCost}
                        </td>
                        <td>{invoice.invoiceDate}</td>
                        <td>
                          <BsBoxArrowInDown
                            style={{
                              width: "75px",
                              height: "15px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.downloadFile(invoice.invoiceId);
                            }}
                          />
                          {/* BsFileArrowDownFill BsBoxArrowInDown */}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
