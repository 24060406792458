import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8083';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = "http://35.200.212.170"; ///customer/api/'
const API_URL = process.env.REACT_APP_BASE_URL;
class CustomerService {
  enableStore(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/stores/${id}/enable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  disableStore(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/stores/${id}/disable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  disable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/customers/${id}/disable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  enable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/customers/${id}/enable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllStores(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/stores?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getFilteredStores(pageNumber, customerId) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/stores/by-customer?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        customerId: `${customerId}`,
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllCustomers(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/customers?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllCorporates() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/corporates`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSingleCustomer(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/customers/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateCustomer(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/customers/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addCustomerExcel(formdata) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/customer/api/customers/upload`, formdata, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  addCustomer(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/customer/api/customers`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  getSingleStore(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/stores/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateStore(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customer/api/stores/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  addStore(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/customer/api/stores`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  getCustomerbyCorporateId(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/customers/by-corporate?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        corporateId: `${id}`,
      },
    });
  }
  getStorebyCustomerId(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/stores/by-customer?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        customerId: `${id}`,
      },
    });
  }
  getCustomerbyCorporateName(name) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customer/api/customers/by-corporatename?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        corporateName: `${name}`,
      },
    });
  }
}
export default new CustomerService();
