import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Tooltip,
    OverlayTrigger,
    Card,
    Container,
    Modal,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './rack.css';
import RackService from '../../../Services/RackService';
import Pagination from 'react-js-pagination';

export default class RackComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            racks: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
  openModal = () => {
      this.setState({ openModal: true });
  }

  closeModal = () => {
      this.setState({ openModal: false });
  }
  handlePageChange (pageNumber) {
      let page = pageNumber - 1;
      this.setState({ currentPage: page });
      RackService.getAllRacks(page).then(res => {
          this.setState({ racks: res.data.racks ||[]});
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log('Mount2');
          console.log(res.data);
      });
  }
  componentDidMount () {
      console.log('Mount');
      let activePage = this.state.currentPage;
      RackService.getAllRacks(activePage).then(res => {
          this.setState({ racks: res.data.racks ||[]});
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log('Mount2');
          console.log(res.data);
      });
  }
  render () {
      let { currentPage } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainRack'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Rack</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Rack</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <div className='m-2'>
                  <Link to='/addRack'>
                      <Button className='mr-3' variant='light'>
              Add Rack
                      </Button>
                  </Link>
                  <Link to='/rackDashboard'>
                      <Button className='mr-3' variant='light'>
              Dashboard
                      </Button>
                  </Link>
                  <Link to='/addRackExcel'>
                      <Button variant='light'>Add Excel</Button>
                  </Link>
              </div>
              <Row>
                  <Col>
                      <Card className='customerCard'>
                          <Table striped bordered hover>
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Rack Name</th>
                                      <th>Barcode</th>
                                      <th>Inserted Date</th>
                                      <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {this.state.racks.length === 0 ? (
                                      <div></div>
                                  ) : (
                                      this.state.racks.map((rack, idx) => (
                                          <tr key={idx}>
                                              <td>{idx + 1+ currentPage * 10}</td>
                                              <td>{rack.rackName}</td>
                                              <td>{rack.barcode}</td>
                                              <td>{rack.insertedUserDate}</td>
                                              <td>
                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                      <Link
                                                          to={{
                                                              pathname: '/updateRack',
                                                              state: { id: rack.rackId }
                                                          }}
                                                      >
                                                          <Button variant='success'>
                                                              <BiEdit />
                                                          </Button>
                                                      </Link>
                                                  </OverlayTrigger>
                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                      <Button
                                                          variant='danger'
                                                          className='ml-2'
                                                          onClick={this.openModal}
                                                      >
                                                          <MdDeleteForever />
                                                      </Button>
                                                  </OverlayTrigger>
                                              </td>
                                          </tr>
                                      ))
                                  )}
                              </tbody>
                          </Table>
                          <Modal
                              className='textCenter'
                              show={this.state.openModal}
                              onHide={this.closeModal}
                          >
                              <Modal.Header closeButton>Delete</Modal.Header>
                              <Modal.Body>Do you really want to delete?</Modal.Body>
                              <Modal.Footer>
                                  <Button variant='danger' onClick={this.closeModal}>
                  Delete
                                  </Button>
                              </Modal.Footer>
                          </Modal>
                      </Card>
                  </Col>
              </Row>
              <div className='d-flex justify-content-center mt-2 mb-2'>
                  <Pagination
                      hideNavigation
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalItems}
                      pageRangeDisplayed={10}
                      itemClass='page-item'
                      linkClass='btn btn-light'
                      onChange={this.handlePageChange.bind(this)}
                  />
              </div>
          </Container>
      );
  }
}
