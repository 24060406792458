import React, { Component } from 'react';
import {
    Row,
    Table,
    Card,
    Container
} from 'react-bootstrap';
import CustomerOrderService from '../../Services/CustomerOrderService';
import Pagination from 'react-js-pagination';
export default class DispatchedOrders extends Component {
    constructor (props) {
        super(props);
        this.state = {
            dispatches: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        CustomerOrderService.getAllDispatch(page).then(res => {
            this.setState({ dispatches: res.data.dispatches ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        CustomerOrderService.getAllDispatch(activePage).then(res => {
            this.setState({ dispatches: res.data.dispatches ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    render() {
        return (
            <Container className='mainDispatch'>
                <Row>
                    <Card className='customerCard'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Invoice Number</th>
                                    <th>Customer Detail</th>
                                    <th>Zone Detail</th>
                                    <th>Total Items</th>
                                    <th>Invoice Data</th>
                                    <th>Dispatch Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dispatches.length === 0 ? (
                                    <div></div>
                                ) : (
                                    this.state.dispatches.map((dispatch, idx) => (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>{dispatch.invoiceNumber}</td>
                                            <td><div>
                                            Customer Name: {dispatch.customerName}
                                            </div>
                                            <div>
                                            Store Name: {dispatch.storeName}
                                            </div></td>
                                            <td>{dispatch.zoneDetail}</td>
                                            <td>{dispatch.totalItems}</td>
                                            <td>{dispatch.invoiceDate}</td>
                                            <td>{dispatch.dispatchStatus}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container>
        );
    }
}
