import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Tooltip,
    Container,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './area.css';
import Pagination from 'react-js-pagination';
import ZoneService from '../../../Services/ZoneService';

export default class AreaComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            areas: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        ZoneService.getAllAreas(page).then(res => {
            this.setState({ areas: res.data.areas||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        ZoneService.getAllAreas(activePage).then(res => {
            this.setState({ areas: res.data.areas||[] });
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    render () {
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainArea'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Area</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Configuration</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Area</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addArea'>
                        <Button className='mr-3' variant='light'>
              Add Area
                        </Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Area Name</th>
                                        <th>Inserted Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.areas.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.areas.map((area, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>{area.areaName}</td>
                                                <td>{area.insertedUserDate}</td>
                                                <td><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                    <Link
                                                        to={{
                                                            pathname: '/updateArea',
                                                            state: { id: area.areaId }
                                                        }}
                                                    >
                                                        <Button variant='success'>
                                                            <BiEdit />
                                                        </Button>
                                                    </Link>
                                                </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container>
        );
    }
}
