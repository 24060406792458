import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./order.css";
import AuthenticationService from "../../Services/AuthenticationService";

export default class OrderSchedule extends Component {
  constructor(props) {
    super(props);
    this.userid = sessionStorage.getItem("userid");
    this.state = {
      stages: [
        {
          stageId: 1,
          stageName: "Stage 1",
        },
        {
          stageId: 2,
          stageName: "Stage 2",
        },
        {
          stageId: 3,
          stageName: "Stage 3",
        },
      ],
      schedule: [],
      ROLE: String,
    };
  }

  componentDidMount() {
    this.setState({ ROLE: AuthenticationService.getRole() });
  }

  addSchedule() {
    this.setState({
      schedule: [
        {
          stageId: 1,
          numberOfWeeks: 1,
          startDate: "",
        },
      ],
    });
  }

  deleteSchedule() {}

  render() {
    let { stages, ROLE } = this.state;

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Order</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Profiles
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/Order" }} linkAs={Link}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Order</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container className="homeComponent pb-5">
            <Card>
              <Card.Header className="px-5 gh-card-header">
                <Card.Title className="card-title mb-0 text-left">
                  <h4 className="mb-0">Order Schedule</h4>
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-5">
                <Form>
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Stage</Form.Label>
                        <Form.Control as="select">
                          <option>Select Stage</option>
                          {stages.map((stage, idx) => (
                            <option key={idx} value={stage.stageId}>
                              {stage.stageName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="customerId">
                        <Form.Label>Number of Weeks</Form.Label>
                        <Form.Control as="select">
                          <option>Select Week</option>
                          <option>1</option>
                          <option>2</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Form.Label className="text-white">Schedule</Form.Label>
                        <Button variant="gh-light" onClick={this.addSchedule}>
                          Add New Schedule
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Form.Control as="select">
                          <option>Select Stage</option>
                          {stages.map((stage, idx) => (
                            <option key={idx} value={stage.stageId}>
                              {stage.stageName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="customerId">
                        <Form.Control as="select">
                          <option>Select Week</option>
                          <option>1</option>
                          <option>2</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Form.Control type="date" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group>
                        <Button
                          variant="gh-success"
                          onClick={this.deleteSchedule}
                        >
                          X
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="text-right">
                      <Link to="/order">
                        <Button className="mt-3" variant="gh-light">
                          Back
                        </Button>
                      </Link>
                      <Button
                        className="mt-3 ml-3"
                        variant="gh-success"
                        type="submit"
                        onClick={this.addOrder}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
