import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Tooltip,
    Container,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './product.css';
import { MdDone } from 'react-icons/md';
import ProductService from '../../../Services/ProductService';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';


export default class ProductComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            products: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        ProductService.getAllProducts(page).then(res => {
            this.setState({ products: res.data.products ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }

    enable (id) {
        ProductService.enable(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Product has been enabled now', 'Enabled');
            ProductService.getAllProducts(page).then(res => {
                this.setState({ products: res.data.products ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
  
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    disable (id) {
        ProductService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Product has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            ProductService.getAllProducts(page).then(res => {
                this.setState({ products: res.data.products ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
  
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }

    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        ProductService.getAllProducts(activePage).then(res => {
            this.setState({ products: res.data.products ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    render () {

        let { currentPage } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <Container className='mainProduct'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>Product</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <Link to='/home'>
                                    <FaHome />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/home'> Configuration</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Product</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <div className='m-2'>
                    <Link to='/addProduct'>
                        <Button className='mr-3' variant='light'>
              Add Product
                        </Button>
                    </Link>
                    <Link to='/productAddExcel'>
                        <Button variant='light'>Add Excel</Button>
                    </Link>
                </div>
                <Row>
                    <Col>
                        <Card className='customerCard'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Recipe Cost</th>
                                        <th>Category</th>
                                        <th>Basket Size</th>
                                        <th>Barcode</th>
                                        <th>Zone Type</th>
                                        <th>Space Required (Sq.ft/Unit)</th>
                                        <th>Inserted Date</th>
                                        <th className='actionWidth'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.products.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        this.state.products.map((product, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1+ currentPage * 10}</td>
                                                <td>{product.productName}</td>
                                                <td>{product.currency} {product.price}</td>
                                                <td>{product.currency} {product.recipePrice}</td>
                                                <td>
                        Category:{product.categoryName} Sub Category:
                                                    {product.subCategoryName}
                                                </td>
                                                <td>{product.bucketSize}</td>
                                                <td>{product.barcodeString}</td>
                                                <td>{product.zoneType}</td>
                                                <td>{product.requiredSpaceSize}</td>
                                                <td>{product.insertedDate}</td>
                                                <td><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                    <Link
                                                        to={{
                                                            pathname: '/updateProduct',
                                                            state: { id: product.productId },
                                                            comp:{category:product.categoryId}
                                                        }}
                                                    >
                                                        <Button variant='success'>
                                                            <BiEdit />
                                                        </Button>
                                                    </Link>
                                                </OverlayTrigger>
                                                {product.isDeleted ? (
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Enable</Tooltip>}>
                                                        <Button
                                                            variant='success'
                                                            className='ml-2'
                                                            onClick={() => {
                                                                this.enable(product.productId);
                                                            }}
                                                        >
                                                            <MdDone />
                                                        </Button>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Disable</Tooltip>}><Button
                                                        variant='danger'
                                                        className='ml-2'
                                                        onClick={() => {
                                                            this.disable(product.productId);
                                                        }}
                                                    >
                                                        <MdDeleteForever />
                                                    </Button>
                                                    </OverlayTrigger>
                                                )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
                <NotificationContainer />
            </Container>
        );
    }
}
