import React, { Component } from 'react';
import './home.css';
import {
    Card,
    Container,
    Breadcrumb,
    Badge,
    Row,
    Col,
    Table
} from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { FaHome } from 'react-icons/fa';
import { FcDepartment } from 'react-icons/fc';
import { FcBusinessman } from 'react-icons/fc';
import { FcManager } from 'react-icons/fc';
import { FcInTransit } from 'react-icons/fc';
import MasterDataService from '../../../Services/MasterDataService';

export default class HomeComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            corporateCount:String,
            customerCount:String,
            supplierCount:String,
            storeCount:String,
            monthlyStats: [],
            oderStatusStats: [],
            latestOrders: [],
            charData:[]
        };
    }
    componentDidMount () {
        MasterDataService.getAllDashboardData().then(res => {
            this.setState({ monthlyStats: res.data.monthlyStats ||[],
                oderStatusStats: res.data.oderStatusStats ||[],
                latestOrders: res.data.latestOrders ||[],
                corporateCount:  res.data.corporateCount,
                customerCount:  res.data.customerCount,
                supplierCount:  res.data.supplierCount,
                storeCount:  res.data.storeCount});
            const charData=[['month','Revenue']];
            res.data.monthlyStats.map((val)=>
                charData.push([val.month,val.revenue]));
            console.log(charData);
            this.setState({charData:charData});
        });
    }
    render() {
        let{
            corporateCount,
            customerCount,
            supplierCount,
            storeCount,
            monthlyStats,
            oderStatusStats,
            charData,
            latestOrders
        }=this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        const ColoredBigLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 6
                }}
            />
        );
        return (
            <Container className='homeComponent'>
                <Row className='breadcrum'>
                    <Col>
                        <h4>DashBoard</h4>
                    </Col>
                    <Col>
                        <Breadcrumb className='alignRight'>
                            <Breadcrumb.Item>
                                <FaHome />
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <ColoredLine color='grey' />
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col md="auto">
                                            Corporates 
                                            </Col>
                                            <Col className='text-right'>
                                                <FcBusinessman/> {corporateCount}
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col md="auto">
                                            Customers 
                                            </Col>
                                            <Col className='text-right'>
                                                <FcManager/> {customerCount} 
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col md="auto">
                                            Stores 
                                            </Col>
                                            <Col className='text-right'>
                                                <FcDepartment /> {storeCount} 
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col md="auto">
                                                Suppliers 
                                            </Col>
                                            <Col className='text-right'>
                                                <FcInTransit/> {supplierCount} 
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={8} sm={6} xs={12}>
                                {monthlyStats.length === 0 ? (
                                    <div></div>
                                ) : (
                                    <Chart
                                        width={'100%'}
                                        height={'380px'}
                                        chartType="PieChart"
                                        data={charData}
                                        options={{
                                            areaOpacity: 0.7,
                                            backgroundColor:{
                                                color: 'none',
                                                fill: 'none',
                                            },
                                            title: 'Monthly Revenue Report',
                                            isStacked: true,
                                            animation:{
                                                easing: 'inAndOut',
                                            },
                                            hAxis: { title: 'Months', gridlines: {
                                                color: '#f3f3f3'
                                            },titleTextStyle: { color: 'black' } },
                                            vAxis: { minValue: 0 },
                                            // For the legend to fit, we make the chart area smaller
                                            //chartArea: { width: '50%', height: '70%' },
                                            lineWidth: 25
                                        }}
                                    />
                                )}
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12} className='mt-5'>
                                <Row>
                                    <Col className='text-center mb-3'>
                                        <span ><b>Production Updates</b></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {oderStatusStats.length === 0 ? (
                                            <div></div>
                                        ) : (
                                            oderStatusStats.map((stat, idx) => (
                                                <Row key='idx'>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                {stat.orderStatus}
                                                            </Col>
                                                            <Col className='text-right'>
                                                                {stat.orderCount}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <ColoredBigLine color='#152f4f'/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ))
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header className='text-center'><b>Latest orders</b></Card.Header>
                                    <Table striped bordered size="sm">
                                        <thead>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>Customer Name</th>
                                                <th>Product Name</th>
                                                <th>Store Name</th>
                                                <th>Order Status</th>
                                                <th>Order Number</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {latestOrders.length === 0 ? (
                                                <div></div>
                                            ) : (
                                                latestOrders.map((order, idx) => (
                                                    <tr key={idx}>
                                                        <td>{order.orderId}</td>
                                                        <td>{order.customerName}</td>
                                                        <td>{order.productName}</td>
                                                        <td>{order.storeName}</td>
                                                        <Badge ><td>{order.orderStatus}</td></Badge>
                                                        <td>{order.orderNumber}</td>
                                                        <td>{order.quantity}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}