import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './area.css';
import ZoneService from '../../../Services/ZoneService';

export default class UpdateArea extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            area: {
                areaName: String
            }
        };
    }
  changeHandler = e => {
      let area = this.state.area;
      area[e.target.name] = e.target.value;
      this.setState({ area }, () => console.log(this.state.area));
  }
  componentDidMount () {
      let id = this.state.id;
      ZoneService.getSingleArea(id).then(res => {
          this.setState({ area: res.data||[] });
      });
  }
  updateArea = e => {
      e.preventDefault();
      let id = this.state.id;
      let area = this.state.area;
      console.log(area);
      ZoneService.updateArea(id, area)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/area');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { areaName } = this.state.area;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainCategory mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Area</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/area'>Area</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Area</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Row>
                  <Col>
                      <Card>
                          <Card.Header className='text-center'>
                              <Card.Title>
                                  <h4>Update Area</h4>
                              </Card.Title>
                          </Card.Header>
                          <Card.Body>
                              <Form>
                                  <Row>
                                      <Col lg={4} md={6} sm={6} xs={12}>
                                          <Form.Group controlId='areaName'>
                                              <Form.Label>Area Name</Form.Label>
                                              <Form.Control
                                                  type='text'
                                                  name='areaName'
                                                  onChange={this.changeHandler}
                                                  value={areaName}
                                                  placeholder='Area Name'
                                              ></Form.Control>
                                          </Form.Group>
                                      </Col>
                                  </Row>
                                  <Row className='centerHorizantalButton'>
                                      <Button
                                          className='mt-3 mr-3'
                                          variant='success'
                                          type='submit'
                                          onClick={this.updateArea}
                                      >
                  Submit
                                      </Button>
                                      <Link to='/area'>
                                          <Button className='mt-3' variant='light'>
                    Back
                                          </Button>
                                      </Link>
                                  </Row>
                              </Form>
                          </Card.Body>
                      </Card>
                  </Col>
              </Row>
          </Container>
      );
  }
}
