import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Tooltip,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import customerService from '../../../Services/CustomerService';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { MdDone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class CustomerComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            customers: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }

    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        customerService.getAllCustomers(page).then(res => {
            this.setState({ customers: res.data.customers||[] });
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }

    enable (id) {
        customerService.enable(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            customerService.getAllCustomers(page).then(res => {
                this.setState({ customers: res.data.customers ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    disable (id) {
        customerService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            customerService.getAllCustomers(page).then(res => {
                this.setState({ customers: res.data.customers ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }

    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        console.log(activePage);
        customerService.getAllCustomers(activePage).then(res => {
            this.setState({ customers: res.data.customers ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    render () {
        let { currentPage } = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <div>
                <Container className='mainCustomer'>
                    <Row className='breadcrum'>
                        <Col>
                            <h4>Customer</h4>
                        </Col>
                        <Col>
                            <Breadcrumb className='alignRight'>
                                <Breadcrumb.Item>
                                    <Link to='/home'>
                                        <FaHome />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to='/home'> Profiles</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Customer</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <ColoredLine color='grey' />
                    <div className='m-2'>
                        <Link to='/addCustomerExcel'>
                            <Button className='mr-3' variant='light'>
                Add Excel
                            </Button>
                        </Link>

                        <Link to='/addCustomer'>
                            <Button variant='light'>Add Customer</Button>
                        </Link>
                    </div>
                    <Row>
                        <Col>
                            <Card className='customerCard'>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Corporate Name</th>
                                            <th>Customer Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Currency</th>
                                            <th>Inserted Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.customers.length === 0 ? (
                                            <div></div>
                                        ) : (
                                            this.state.customers.map((customer, idx) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1 + currentPage * 10}</td>
                                                    <td>{customer.corporateName}</td>
                                                    <td>{customer.customerName}</td>
                                                    <td>{customer.emailAddress}</td>
                                                    <td>{customer.phoneNumber}</td>
                                                    <td>{customer.currency}</td>
                                                    <td>{customer.insertedDate}</td>
                                                    <td>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                            <Link
                                                                to={{
                                                                    pathname: '/updateCustomer',
                                                                    state: { id: customer.customerId }
                                                                }}
                                                            >
                                                                <Button variant='success'>
                                                                    <BiEdit />
                                                                </Button>
                                                            </Link>
                                                        </OverlayTrigger>
                                                        {customer.isDeleted ? (
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Enable</Tooltip>}>
                                                                <Button
                                                                    variant='success'
                                                                    className='ml-2'
                                                                    onClick={() => {
                                                                        this.enable(customer.customerId);
                                                                    }}
                                                                >
                                                                    <MdDone />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Disable</Tooltip>}>
                                                                <Button
                                                                    variant='danger'
                                                                    className='ml-2'
                                                                    onClick={() => {
                                                                        this.disable(customer.customerId);
                                                                    }}
                                                                >
                                                                    <MdDeleteForever />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-center mt-2 mb-2'>
                        <Pagination
                            hideNavigation
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalItems}
                            pageRangeDisplayed={10}
                            itemClass='page-item'
                            linkClass='btn btn-light'
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                </Container>

                <NotificationContainer />

            </div>
        );
    }
}
