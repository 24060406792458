import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './tax.css';
import MasterDataService from '../../../Services/MasterDataService';
export default class UpdateTax extends Component {
    constructor (props) {
        super(props);
        this.state = {
            updatedTax: {
                tax: Number,
                taxNumber: String,
                businessNumber: String
            }
        };
    }
    componentDidMount () {
        console.log('Mount');
        MasterDataService.getTax().then(res => {
            this.setState({ updatedTax: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
    }
  changeHandler = e => {
      let updatedTax = this.state.updatedTax;
      updatedTax[e.target.name] = e.target.value;
      this.setState({ updatedTax }, () => console.log(this.state.updatedTax));
  }

  updateTax = e => {
      e.preventDefault();
      let updatedTax = this.state.updatedTax;
      MasterDataService.updateTax(updatedTax)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/tax');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { tax, taxNumber, businessNumber } = this.state.updatedTax;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainTax mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4> Update HST</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/tax'>Tax</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Tax</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Update Tax</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='tax'>
                                      <Form.Label>HST% </Form.Label>
                                      <Form.Control
                                          type='number'
                                          name='tax'
                                          onChange={this.changeHandler}
                                          value={tax}
                                          placeholder='tax'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='taxNumber'>
                                      <Form.Label>HST Number </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='taxNumber'
                                          onChange={this.changeHandler}
                                          value={taxNumber}
                                          placeholder='taxNumber'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='businessNumber'>
                                      <Form.Label>Business Number </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='businessNumber'
                                          onChange={this.changeHandler}
                                          value={businessNumber}
                                          placeholder='businessNumber'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.updateTax}
                              >
                  Submit
                              </Button>
                              <Link to='/tax'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
