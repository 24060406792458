import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import zoneService from '../../../Services/ZoneService';

export default class UpdateZone extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            zones: [],
            areas:[],
            zone: {
                zoneName: String,
                zoneTypeId: Number,
                capacity: Number,
                areaId: Number,
                isDeleted: false,
                userId: 1
            }
        };
    }
    componentDidMount () {
        let id = this.state.id;
        zoneService.getAllZoneType().then(res => {
            this.setState({ zones: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        zoneService.getSingleZone(id).then(res => {
            this.setState({ zone: res.data ||[]});
        });
        zoneService.getAllAreasWithoutPagination().then(res => {
            this.setState({ areas: res.data.areas ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
    }

  changeHandler = e => {
      let zone = this.state.zone;
      zone[e.target.name] = e.target.value;
      this.setState({ zone }, () => console.log(this.state.zone));
  }
  
  updateZone = e => {
      e.preventDefault();
      let id = this.state.id;
      let zone = this.state.zone;
      zoneService
          .updateZone(id, zone)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/zone');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { zoneName,areaId, zoneTypeId, capacity} = this.state.zone;
      let{zones,areas}=this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainZone mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Zone</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/zone'>Zone</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update Zone</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Update Zone</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='zoneName'>
                                      <Form.Label>Zone Name </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='zoneName'
                                          onChange={this.changeHandler}
                                          value={zoneName}
                                          placeholder='Zone Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='zoneTypeId'>
                                      <Form.Label>Zone Type </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='zoneTypeId'
                                          onChange={this.changeHandler}
                                          value={zoneTypeId}
                                          placeholder='Zone Type'
                                      >
                                          {zones.map((zone, idx) => (
                                              <option key={idx} value={zone.zonetypeId}>
                                                  {zone.zonetype}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='capacity'>
                                      <Form.Label>Capacity (Sq.Ft)</Form.Label>
                                      <Form.Control
                                          type='number'
                                          name='capacity'
                                          onChange={this.changeHandler}
                                          value={capacity}
                                          placeholder='Capacity'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='areaId'>
                                      <Form.Label>Area </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='areaId'
                                          onChange={this.changeHandler}
                                          value={areaId}
                                          placeholder='Area'
                                      >
                                          <option>Select Area</option>
                                          {areas.map((area, idx) => (
                                              <option key={idx} value={area.areaId}>
                                                  {area.areaName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.updateZone}
                              >
                  Submit
                              </Button>
                              <Link to='/zone'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
