import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './rack.css';
import RackService from '../../../Services/RackService';

export default class AddRack extends Component {
    constructor (props) {
        super(props);
        this.state = {
            rackName: String,
            barcode: String
        };
    }
  changeHandler = e => {
      this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }

  addRack = e => {
      e.preventDefault();
      let rack = {
          rackName: this.state.rackName,
          barcode: this.state.barcode
      };
      console.log(rack);
      RackService
          .addRack(rack)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/rack');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { rackName, barcode } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainRack mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Rack</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/rack'>Rack</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Add Rack</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Add Rack</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='rackName'>
                                      <Form.Label>Rack Name </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='rackName'
                                          onChange={this.changeHandler}
                                          value={rackName}
                                          placeholder='Rack Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='barcode'>
                                      <Form.Label>Barcode </Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='barcode'
                                          onChange={this.changeHandler}
                                          value={barcode}
                                          placeholder='Barcode'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.addRack}
                              >
                  Submit
                              </Button>
                              <Link to='/rack'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
