import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import prouctService from '../../../Services/ProductService';
import './category.css';

export default class UpdateSubCategory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            subCategory: {
                categoryId:Number,
                categoryName: String
            },
            categories: []
        };
    }
    componentDidMount () {
        console.log('Mount');
        prouctService.getAllCategoriesWithoutPagination().then(res => {
            this.setState({ categories: res.data.categories ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        let id = this.state.id;
        prouctService.getSingleSubCategory(id).then(res => {
            this.setState({ subCategory: res.data ||[]});
        });
    }
  changeHandler = e => {
      let subCategory = this.state.subCategory;
      subCategory[e.target.name] = e.target.value;
      this.setState({ subCategory }, () => console.log(this.state.subCategory));
  }
  
  updateSubCategory = e => {
      e.preventDefault();
      let id = this.state.id;
      let subCategory = this.state.subCategory;
      console.log(subCategory);
      prouctService
          .updateSubCategory(id, subCategory)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/subCategory');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { subCategoryName, categoryId } = this.state.subCategory;
      let {categories}=this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainCategory mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Update SubCategory</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Configuration</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/SubCategory'>SubCategory</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Update SubCategory</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Update SubCategory</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='subCategoryName'>
                                      <Form.Label>Sub Category Name</Form.Label>
                                      <Form.Control
                                          type='text'
                                          name='subCategoryName'
                                          onChange={this.changeHandler}
                                          value={subCategoryName}
                                          placeholder='Sub Category Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='categoryId'>
                                      <Form.Label>Category Name</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='categoryId'
                                          onChange={this.changeHandler}
                                          value={categoryId}
                                      >{categories.length === 0 ? (
                                              <option>Select Zone</option>
                                          ) : (
                                              categories.map((category, idx) => (
                                                  <option key={idx} value={category.id}>{category.categoryName}</option>
                                              )))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.updateSubCategory}
                              >
                  Submit
                              </Button>
                              <Link to='/subCategory'>
                                  <Button className='mt-3' variant='light'>
                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
