import React, { Component } from 'react';
import './store.css';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Form,
    Tooltip,
    OverlayTrigger,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDone } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import customerService from '../../../Services/CustomerService';
import Pagination from 'react-js-pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class StoreComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stores: [],
            corporates: [],
            customerId:Number,
            customers: [],
            addresses: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        customerService.getAllStores(page).then(res => {
            this.setState({ stores: res.data.stores ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    enable (id) {
        customerService.enableStore(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            customerService.getAllStores(page).then(res => {
                this.setState({ stores: res.data.stores ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    disable (id) {
        customerService.disableStore(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            customerService.getAllStores(page).then(res => {
                this.setState({ stores: res.data.stores ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        customerService.getAllStores(activePage).then(res => {
            this.setState({ stores: res.data.stores ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
        customerService.getAllCorporates().then(res => {
            this.setState({ corporates: res.data.corporates||[] });
        });
    }
  changeHandler = e => {
      switch (e.target.name) {
      case 'corporateName':
          let name = e.target.value;
          console.log(name);
          customerService.getCustomerbyCorporateName(name).then(res => {
              this.setState({ customers: res.data.customers });
          });
          break;
      default:
          break;
      }
      this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }
  filterStore = e => {
      e.preventDefault();
      let activePage = this.state.currentPage;
      let id=this.state.customerId;
      customerService
          .getFilteredStores(activePage,id)
          .then(res => {
              this.setState({ stores: res.data.stores ||[]});
              console.log('Mount2');
              console.log(res.data);
              const totalPages = res.data.totalPages;
              const totalItems = res.data.totalItems;

              this.setState({ totalPages: totalPages });
              this.setState({ totalItems: totalItems });
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let {
          currentPage,
          corporateName,
          corporates,
          customerId,
          customers
      } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mainStore'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>Stores</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Profiles</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Store</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Row>
                  <Col >
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='corporateName'>
                                      <Form.Label>Corporate Name </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='corporateName'
                                          onChange={this.changeHandler}
                                          value={corporateName}
                                      >
                                          <option>Select Corporate</option>
                                          {corporates.map((corporate, idx) => (
                                              <option key={idx} value={corporate.corporateName}>
                                                  {corporate.corporateName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='customerId'>
                                      <Form.Label>Customer Name </Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='customerId'
                                          onChange={this.changeHandler}
                                          value={customerId}
                                      >
                                          <option>Select Customers</option>
                                          {customers.map((customer, idx) => (
                                              <option key={idx} value={customer.customerId}>
                                                  {customer.customerName}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Button
                                      className=' ml-5 downButtonStore'
                                      variant='success'
                                      type='submit'
                                      onClick={this.filterStore}
                                  >
                                    Submit
                                  </Button>
                              </Col>
                          </Row>
                      </Form>
                  </Col>
              </Row>
              <div className='mb-3'>
                  <Link to='/addStore'>
                      <Button variant='light'>Add Store</Button>
                  </Link>
              </div>
              <Row>
                  <Col>
                      <Card className='customerCard'>
                          <Table striped bordered hover>
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Corporate Name</th>
                                      <th>Customer Name</th>
                                      <th>Store Name</th>
                                      <th>Store Number</th>
                                      <th>Email</th>
                                      <th>Phone</th>
                                      <th>Address</th>
                                      <th>Inserted Date</th>
                                      <th className='actionWidth'>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {this.state.stores.length === 0 ? (
                                      <div></div>
                                  ) : (
                                      this.state.stores.map((store, idx) => (
                                          <tr key={idx}>
                                              <td>{idx + 1 + currentPage * 10}</td>
                                              <td>{store.corporateName}</td>
                                              <td>{store.customerName}</td>
                                              <td>{store.storeName}</td>
                                              <td>{store.storeNumber}</td>
                                              <td>{store.emailAddress}</td>
                                              <td>{store.phoneNumber}</td>
                                              <td>{store.addresses[0].address1}</td>
                                              <td>{store.insertedDate}</td>
                                              <td>
                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                      <Link
                                                          to={{
                                                              pathname: '/updateStore',
                                                              state: { id: store.storeId }
                                                          }}
                                                      >
                                                          <Button variant='success'>
                                                              <BiEdit />
                                                          </Button>
                                                      </Link>
                                                  </OverlayTrigger>
                                                  {store.isDeleted ? (
                                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Enable</Tooltip>}>
                                                          <Button
                                                              variant='success'
                                                              className='ml-2'
                                                              onClick={() => {
                                                                  this.enable(store.storeId);
                                                              }}
                                                          >
                                                              <MdDone />
                                                          </Button>
                                                      </OverlayTrigger>
                                                  ) : (<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Disable</Tooltip>}>
                                                      <Button
                                                          variant='danger'
                                                          className='ml-2'
                                                          onClick={() => {
                                                              this.disable(store.storeId);
                                                          }}
                                                      >
                                                          <MdDeleteForever />
                                                      </Button>
                                                  </OverlayTrigger>
                                                  )}
                                              </td>
                                          </tr>
                                      ))
                                  )}
                              </tbody>
                          </Table>
                      </Card>
                  </Col>
              </Row>
              <div className='d-flex justify-content-center mt-2 mb-2'>
                  <Pagination
                      hideNavigation
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalItems}
                      pageRangeDisplayed={10}
                      itemClass='page-item'
                      linkClass='btn btn-light'
                      onChange={this.handlePageChange.bind(this)}
                  />
              </div>
              <NotificationContainer />
          </Container>
      );
  }
}
