import React, { Component } from 'react';
import { Card, Form, Container, Button, Row, Col,Breadcrumb } from 'react-bootstrap';
import UserService from '../../../Services/UsersService';
import MasterDataService from '../../../Services/MasterDataService';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export default class AddUser extends Component {
    constructor (props) {
        super(props);
        this.state = {
            countries: [],
            roles:[],
            roleId: String,
            username: String,
            password: String,
            firstName: String,
            middleName: String,
            lastName: String,
            email: String,
            phoneNumber: String,
            gender: 'male',
            insertedUserId: Number,
            address: {
                address1: String,
                address2: String,
                city: String,
                region: String,
                province: String,
                country: String
            }
        };
    }
    componentDidMount () {
        console.log('Mount');
        MasterDataService.getAllCountries().then(res => {
            this.setState({ countries: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        UserService.getAllRoles().then(res=>{
            this.setState({ roles: res.data ||[]});
        });
    }
    onChange (e) {
        console.log('inside if');
        let address = this.state.address;
        console.log(e.target.name);
        address[e.target.name] = e.target.value;
        this.setState({ address }, () => console.log(this.state.address));
    }

  changeHandler = e => {
      let formErrors = { ...this.state.formErrors };
      //Switch method for tackling particular formError
      console.log(e.target.name);
      console.log(e.target);
      switch (e.target.name) {
      case 'gender':
          e.target.value=e.target.id;
          break;
      default:
          break;
      }
      this.setState({ formErrors, [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }

  submitUser = e => {
      e.preventDefault();
      let user = {
          username: this.state.username,
          password: this.state.password,
          email: this.state.email,
          firstName: this.state.firstName,
          middleName: this.state.middleName,
          lastName: this.state.lastName,
          phoneNumber: this.state.phoneNumber,
          gender: this.state.gender,
          address: this.state.address,
          roleId: this.state.roleId
      };
      UserService
          .addUser(user)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/user');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let {
          countries,
          roles,
          roleId,
          firstName,
          middleName,
          lastName,
          email,
          phoneNumber,
          username,
          password,
          gender,
          address
      } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
          <Container className='mb-5'>
              <Row className='breadcrum'>
                  <Col>
                      <h4>User</h4>
                  </Col>
                  <Col>
                      <Breadcrumb className='alignRight'>
                          <Breadcrumb.Item>
                              <Link to='/home'>
                                  <FaHome />
                              </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/home'> Profiles</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                              <Link to='/user'>User</Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Add User</Breadcrumb.Item>
                      </Breadcrumb>
                  </Col>
              </Row>
              <ColoredLine color='grey' />
              <Card>
                  <Card.Header className='text-center'>
                      <Card.Title>
                          <h4>Add User</h4>
                      </Card.Title>
                  </Card.Header>
                  <Card.Body>
                      <Form>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group noValidate validated='validated' controlId='firstName'>
                                      <Form.Label>First Name</Form.Label>
                                      <Form.Control required
                                          type='text'
                                          name='firstName'
                                          onChange={this.changeHandler}
                                          value={firstName}
                                          placeholder='First Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='middleName'>
                                      <Form.Label>Middle Name </Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='middleName'
                                          onChange={this.changeHandler}
                                          value={middleName}
                                          placeholder='Middle Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='lastName'>
                                      <Form.Label>Last Name </Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='lastName'
                                          onChange={this.changeHandler}
                                          value={lastName}
                                          placeholder='Last Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='email'>
                                      <Form.Label>Email Address </Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='email'
                                          onChange={this.changeHandler}
                                          value={email}
                                          placeholder='Email Address'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='phoneNumber'>
                                      <Form.Label>Phone Number</Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='phoneNumber'
                                          onChange={this.changeHandler}
                                          value={phoneNumber}
                                          placeholder='Phone Number'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='gender'>
                                      <Form.Label>Gender</Form.Label>
                                      <Form.Check
                                          type='radio'
                                          label='Male'
                                          name='gender'
                                          value={gender}
                                          onChange={this.changeHandler}
                                          checked={gender === 'male'}
                                          id='male'
                                      />
                                      <Form.Check
                                          type='radio'
                                          label='Female'
                                          name='gender'
                                          value={gender}
                                          onChange={this.changeHandler}
                                          checked={gender === 'female'}
                                          id='female'
                                      />
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col>
                                  <Form.Group controlId='address.address1'>
                                      <Form.Label>Address</Form.Label>
                                      <Form.Control
                                          required
                                          type='textarea'
                                          rows='4'
                                          name='address1'
                                          onChange={e => this.onChange(e)}
                                          value={address.address1}
                                          placeholder='Enter address ...'
                                      />
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.country'>
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='country'
                                          onChange={e => this.onChange(e)}
                                          value={address.country}
                                      >
                                          <option>Select Country</option>
                                          {countries.map((country, idx) => (
                                              <option key={idx} value={country.value}>
                                                  {country.value}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.city'>
                                      <Form.Label>City</Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='city'
                                          onChange={e => this.onChange(e)}
                                          value={address.city}
                                          placeholder='City Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.province'>
                                      <Form.Label>Province</Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='province'
                                          onChange={e => this.onChange(e)}
                                          value={address.province}
                                          placeholder='Province Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='address.region'>
                                      <Form.Label>Region</Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='region'
                                          onChange={e => this.onChange(e)}
                                          value={address.region}
                                          placeholder='Region Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group noValidate validated='validated' controlId='username'>
                                      <Form.Label>User Name</Form.Label>
                                      <Form.Control required
                                          type='text'
                                          name='username'
                                          onChange={this.changeHandler}
                                          value={username}
                                          placeholder='User Name'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='password'>
                                      <Form.Label>Password </Form.Label>
                                      <Form.Control
                                          required
                                          type='text'
                                          name='password'
                                          onChange={this.changeHandler}
                                          value={password}
                                          placeholder='Password'
                                      ></Form.Control>
                                  </Form.Group>
                              </Col>
                              <Col lg={4} md={6} sm={6} xs={12}>
                                  <Form.Group controlId='roleId'>
                                      <Form.Label>Role</Form.Label>
                                      <Form.Control
                                          as='select'
                                          name='roleId'
                                          onChange={this.changeHandler}
                                          value={roleId}
                                      >
                                          <option>Select Role</option>
                                          {roles.map((role, idx) => (
                                              <option key={idx} value={role.id}>
                                                  {role.name}
                                              </option>
                                          ))}
                                      </Form.Control>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Row className='centerHorizantalButton'>
                              <Button
                                  className='mt-3 mr-3'
                                  variant='success'
                                  type='submit'
                                  onClick={this.submitUser}
                              >
                                Submit
                              </Button>
                              <Link to='/user'>
                                  <Button className='mt-3' variant='light'>
                                    Back
                                  </Button>
                              </Link>
                          </Row>
                      </Form>
                  </Card.Body>
              </Card>
          </Container>
      );
  }
}
