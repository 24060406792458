import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import BootstrapTable from 'react-bootstrap-table-next';

import { FiUsers } from "react-icons/fi";
import { CiUser } from "react-icons/ci";
import { BiStore } from "react-icons/bi";
import { FiTruck } from "react-icons/fi";
import DeliveryTruck from "../../../Images/icons/DeliveryTruck.svg";

import MasterDataService from '../../../Services/MasterDataService';
import AuthenticationService from "../../../Services/AuthenticationService";

export default class HomeComponent extends Component {

    constructor (props) {

        super(props);

        this.state = {
            corporateCount: 0,
            customerCount: 0,
            supplierCount:0 ,
            storeCount: 0,
            monthlyStats: [],
            oderStatusStats: [],
            latestOrders: [],
            charData:[]
        };
		
        this.columns = [{
            dataField: 'customerName',
            text: 'Banner Name'
          }, {
            dataField: 'productName',
            text: 'Product Name'
          }, {
            dataField: 'storeName',
            text: 'Store Name'
          }, {
            dataField: 'orderStatus',
            text: 'Order Status',
            formatter: function(cell, row){
                return (
                    <b className={'gh-status-'+cell}>{cell}</b>
                );
            }
          }, {
            dataField: 'orderNumber',
            text: 'Order Number'
          }, {
            dataField: 'quantity',
            text: 'Quantity'         
        }];
    }

    componentDidMount () {
        MasterDataService.getAllDashboardData().then(res => {
            this.setState({ 
                monthlyStats: res.data.monthlyStats ||[],
                oderStatusStats: res.data.oderStatusStats ||[],
                latestOrders: res.data.latestOrders ||[],
                corporateCount:  res.data.corporateCount || 0,
                customerCount:  res.data.customerCount,
                supplierCount:  res.data.supplierCount,
                storeCount:  res.data.storeCount
            });
            const charData=[['month','Revenue']];
            res.data.monthlyStats.map((val)=>
                charData.push([val.month,val.revenue]));
            
            this.setState({charData:charData});
        });
    }
    render() {

        let userStatus = AuthenticationService.isUserLoggedIn();
        let userRole = AuthenticationService.getRole();

        const {
            corporateCount,
            customerCount,
            supplierCount,
            storeCount,
            monthlyStats,
            oderStatusStats,
            charData,
            latestOrders
        } = this.state;

        // console.log('charData', charData)
        let firstOrderStatus = [];
        let secondOrderStatus = [];
        console.log(oderStatusStats)
        if(oderStatusStats.length){
            firstOrderStatus = oderStatusStats.slice(0, 4);
            secondOrderStatus = oderStatusStats.slice(4);
        }

        return (
            <div className='gh-bgcolor' >

            <div className="container-fluid">

                {/* cards  */}
                <div className="row mx-auto bg-white p-3 mt-3" style={{ gap:"16px"}}>

                    <div className="col px-0 d-flex align-items-center gh-cards" >                            
                        <div className="text-center gh-cards-icon" style={{backgroundColor: '#299F78'}}> 
                            <FiUsers size={32} color='white' />                                
                        </div>
                        <div className="flex-grow-1" >
                            <h5 className="mt-2 mb-1">Corporates</h5>
                            <div className='h2 my-2 font-weight-bold' style={{color: '#299F78'}}>{this.state.corporateCount}</div>
                        </div>
                    </div>

                    <div className="col px-0 d-flex align-items-center gh-cards" >                            
                        <div className="text-center gh-cards-icon" style={{backgroundColor: '#4EBEBE'}}> 
                            <CiUser size={32} color='white' />                                
                        </div>
                        <div className="flex-grow-1" >
                            <h5 className="mt-2 mb-1" >Banners</h5>
                            <div className='h2 my-2 font-weight-bold' style={{color: '#4EBEBE'}}>{customerCount}</div>
                        </div>
                    </div>

                    <div className="col px-0 d-flex align-items-center gh-cards" >                            
                        <div className="text-center gh-cards-icon" style={{backgroundColor: '#7DC233'}}> 
                            <BiStore size={32} color='white' />                                
                        </div>
                        <div className="flex-grow-1" >
                            <h5 className="mt-2 mb-1">Stores</h5>
                            <div className='h2 my-2 font-weight-bold' style={{color: '#7DC233'}}>{storeCount}</div>
                        </div>
                    </div>

                    <div className="col px-0 d-flex align-items-center gh-cards" >                            
                        <div className="text-center gh-cards-icon" style={{backgroundColor: '#59CBE5'}}> 
                            <img
                                src={DeliveryTruck} 
                                width="32" 
                            ></img>                              
                        </div>
                        <div className="flex-grow-1" >
                            <h5 className="mt-2 mb-1">Suppliers</h5>
                            <div className='h2 my-2 font-weight-bold' style={{color: '#59CBE5'}}>{supplierCount}</div>
                        </div>
                    </div>
            
                </div>

                {/* box  */}
                <div className='row p-3'>
                    <div className='col-6'>
                        <div className='dashboard-card'>
                            <h3 className='h4'>Monthly Revenue Report</h3>
                            {monthlyStats.length === 0 ? (
                                <div></div>
                            ) : (
                                <>
                                <Chart
                                    
                                    width={'100%'}
                                     
                                    chartType="PieChart"
                                    data={charData}
                                    options={{
                                        chartArea: { width:"100%", height:"80%"}, 
                                        legend: { position: 'left', textStyle: {fontSize: 16}   },
                                        areaOpacity: 1,
                                        colors: ['#91E268', '#F2D242', '#299F78'],
                                        backgroundColor:{
                                            color: 'none',
                                            fill: 'none',
                                        },
                                        // tooltip: { trigger: 'none' },
                                        title: '',
                                        isStacked: false,
                                        animation:{
                                            easing: 'inAndOut',
                                        },
                                        hAxis: { 
                                            title: 'Months',
                                            gridlines: {
                                                color: '#f3f3f3'
                                            },
                                            titleTextStyle: { color: 'black' } 
                                        },
                                        vAxis: { minValue: 0 },
                                        // For the legend to fit, we make the chart area smaller
                                        //chartArea: { width: '50%', height: '70%' },
                                        lineWidth: 1,
                                        is3D: false,
                                        pieHole: 0.5,   // make pie to donut
                                    }}
                                />
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='dashboard-card'>
                            <h3 className='h4'>Production Updates</h3>
                            <div className='d-flex justify-content-between mt-2' style={{ gap: '20px '}}>
                                <div className='w-50 mt-3'>
                                { 
                                    firstOrderStatus.map((stat, idx) => (
                                        <div key={idx} className={'d-flex mb-2 justify-content-between gh-status-'+stat.orderStatus} >
                                            <div className='card-bullet'><span className='text-secondary'>{stat.orderStatus}</span></div>
                                            <div>{stat.orderCount}</div>
                                        </div>
                                    ))
                                }
                                </div>
                                <div className='w-50 mt-3'>
                                { 
                                    secondOrderStatus.map((stat, idx) => (
                                        <div key={idx} className={'d-flex mb-2 justify-content-between gh-status-'+stat.orderStatus} >
                                            <div className='card-bullet'><span className='text-secondary'>{stat.orderStatus}</span></div>
                                            <div>{stat.orderCount}</div>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className='homeComponent pb-5 container-fluid' >                  
                <Row className="bg-white mx-auto">
                    <Col> 
                        <h3 className='py-3 h4 border-top-1'>Latest Orders</h3>                        
                        <Row>
                            <Col>
                                <BootstrapTable keyField='orderNumber' data={ latestOrders } columns={ this.columns } bordered={ false } wrapperClasses="gh-table"/>
                            </Col>
                        </Row>
                        {
                            userStatus && userRole == "ROLE_Administrator" && (
                            <Link as={Link} to="/orderStatus" className="float-right" style={{ color: '#F86C34'}}>
                                See All Orders &gt;
                            </Link>)
                        }
                    </Col>
                </Row>
            </div>
            </div>
        );
    }
}