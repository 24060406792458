import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { FcShipped, FcSoundRecordingCopyright } from "react-icons/fc";
import { Link } from "react-router-dom";
import "./rack.css";
import RackService from "../../../Services/RackService";

export default class RackDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      racks: [],
      barcodeString: String,
    };
  }
  componentDidMount() {
    console.log("Mount");
    RackService.getDashboardRacks().then((res) => {
      this.setState({ racks: res.data.racks || [] });
      console.log("Mount2");
      console.log(res.data);
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  //deallocated button click
  deallocateRacksFromTable = (e) => {
    let data = {
      rackId: "",
      barcode: this.state.barcodeString,
    };
    console.log("sampledcdcfdcfad- ", data);
    RackService.deallocateRack(data).then((res) => {
      RackService.getDashboardRacks().then((res) => {
        this.setState({ racks: res.data.racks || [] });
        console.log("Mount2");
        console.log(res.data);
      });
      this.setState({ barcodeString: "" });
    });
  };

  render() {
    let { barcodeString } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );

    return (
      <Container className="mainRack">
        <Row className="breadcrum">
          <Col>
            <h4>Rack</h4>
          </Col>
          <Col>
            <Breadcrumb className="alignRight">
              <Breadcrumb.Item>
                <Link to="/home">
                  <FaHome />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/home"> Configuration</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/rack">Rack</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Rack Dashboard</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <ColoredLine color="grey" />
        <div className="m-2"></div>
        <Row>
          <Col lg={4} md={6} sm={6} xs={12}>
            <Form.Group controlId="barcodeString">
              <Form.Label>Rack Barcode</Form.Label>
              <Form.Control
                type="text"
                name="barcodeString"
                onChange={this.changeHandler}
                value={barcodeString}
                placeholder="Scan Barcode"
                autoComplete="off"
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} style={{ marginTop: "25px" }}>
            <Button onClick={this.deallocateRacksFromTable}>Deallocate</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="customerCard">
              <Row className="mt-2">
                {this.state.racks.length === 0 ? (
                  <div></div>
                ) : (
                  this.state.racks.map((rack, idx) => (
                    <Col
                      key={idx}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      className="mb-2"
                    >
                      {rack.isAllocated ? (
                        <Button variant="warning" className=" btn-block">
                          <FcSoundRecordingCopyright /> {rack.rackName}
                        </Button>
                      ) : (
                        <Button variant="info" className=" btn-block">
                          <FcShipped /> {rack.rackName}
                        </Button>
                      )}
                    </Col>
                  ))
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
