import React, { Component } from "react";
import "./header.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaPortrait,
  FaStoreAlt,
  FaTruckMoving,
  FaUsers,
  FaMoneyCheckAlt,
  FaBoxes,
  FaFileInvoice,
} from "react-icons/fa";
import { BiCarousel } from "react-icons/bi";
import { RiCheckboxMultipleFill, RiAccountPinBoxFill } from "react-icons/ri";
import { ImBoxAdd } from "react-icons/im";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdPersonPinCircle } from "react-icons/md";
import {
  BsBookmarksFill,
  BsBookmarkFill,
  BsBoundingBox,
  BsInboxesFill,
} from "react-icons/bs";
import { FiBox } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import logo from "../../../Images/scharringagreenhouses.png";
import AuthenticationService from "../../../Services/AuthenticationService";
import { BsFillFunnelFill } from "react-icons/bs";
export default class HeaderComponent extends Component {
  render() {
    let userStatus = AuthenticationService.isUserLoggedIn();
    let userRole = AuthenticationService.getRole();
    let username = AuthenticationService.getUsername();
    console.log(userRole);
    return (
      <Navbar
        className="header mb-5"
        variant="dark"
        collapseOnSelect
        expand="lg"
      >
        <div className="container">
          {/* <Navbar.Brand>
            <Link to="/home">
              <img
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="SG"
              ></img>
            </Link>
          </Navbar.Brand> */}
          {/* {!userStatus && ( */}
            <Navbar.Brand className="mt-auto">
              <h2>BLITZER</h2>
            </Navbar.Brand>
          {/* )} */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {userStatus && userRole == "ROLE_Administrator" && (
                <NavDropdown title="Profiles" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/customer">
                      <FaPortrait /> Customers
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/store">
                      <FaStoreAlt /> Stores
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/supplier">
                      <FaTruckMoving /> Supplier
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/user">
                      <FaUsers /> Users
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userStatus && userRole == "ROLE_Administrator" && (
                <NavDropdown title="Configuration" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/category">
                      <BsBookmarksFill /> Category
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/subCategory">
                      <BsBookmarkFill /> Sub Category
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/product">
                      <FiBox /> Product
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/area">
                      <BsBoundingBox /> Area
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/zone">
                      <BiCarousel /> Zone
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/recipe">
                      <BsFillFunnelFill /> Recipe
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/rack">
                      <BsInboxesFill /> Rack
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/tax">
                      <FaMoneyCheckAlt /> Tax Component
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/operatorAllocation">
                      <MdPersonPinCircle /> Operator Allocation
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userStatus && userRole == "ROLE_Administrator" && (
                <NavDropdown title="Material" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/materialProduct">
                      <FaBoxes /> Material Master
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/supplierProduct">
                      <RiAccountPinBoxFill /> Supplier Product
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/purchaseOrder">
                      <GiTakeMyMoney /> Purchase Order
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userStatus && userRole == "ROLE_Stores" && (
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/storeInventory">Store</Link>
                  </Nav.Link>
                </Nav.Item>
              )}
              {userStatus && userRole == "ROLE_Stores" && (
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/materialInventory">Material Inventory</Link>
                  </Nav.Link>
                </Nav.Item>
              )}
              {userStatus &&
                (userRole == "ROLE_Administrator" ||
                  userRole == "ROLE_Operator") && (
                  <Nav.Item>
                    <Nav.Link>
                      <Link to="/dispatch">Dispatch</Link>
                    </Nav.Link>
                  </Nav.Item>
                )}
              {userStatus && userRole == "ROLE_Operator" && (
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/rackDashboard">Rack</Link>
                  </Nav.Link>
                </Nav.Item>
              )}
              {userStatus && userRole == "ROLE_Operator" && (
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/shop">Shop</Link>
                  </Nav.Link>
                </Nav.Item>
              )}
              {userStatus && userRole == "ROLE_Operator" && (
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/materialRequest">Material Request</Link>
                  </Nav.Link>
                </Nav.Item>
              )}
              {userStatus && userRole == "ROLE_Administrator" && (
                <NavDropdown title="Order" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/addOrder">
                      <ImBoxAdd /> Add Order
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/orderStatus">
                      <RiCheckboxMultipleFill /> Order Status
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/invoice">
                      <FaFileInvoice /> Invoice
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userStatus && userRole == "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" && (
                <NavDropdown title="Order" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/addOrder">
                      <ImBoxAdd /> Add Order
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/orderStatus">
                      <RiCheckboxMultipleFill /> Order Status
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/invoice">
                      <FaFileInvoice /> Invoice
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
            {!userStatus && (
              <Nav>
                <Nav.Link>
                  <Link to="/">
                    <CgProfile /> Login
                  </Link>
                </Nav.Link>
              </Nav>
            )}
            {userStatus && (
              <Nav>
                <Nav.Link>
                  <CgProfile /> {username}
                  <Link to="/logout">
                    <span> Logout</span>
                  </Link>
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}
