import React, { Component } from 'react';
import {
    Row,
    Table,
    Card,
    Container
} from 'react-bootstrap';
import MaterialOrder from '../../Services/MaterialOrder';
import Pagination from 'react-js-pagination';

export default class ClosedOrder extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stores: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        MaterialOrder.getAllClosedStore(page).then(res => {
            this.setState({ stores: res.data.storeOrders ||[]});
            console.log('Mount2 closed');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        MaterialOrder.getAllClosedStore(activePage).then(res => {
            this.setState({ stores: res.data.storeOrders ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });

    }
    render() {
        return (
            <Container className='mainStore'>
                <Row>
                    <Card className='customerCard'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Material Name</th>
                                    <th>Requested Quantity</th>
                                    <th>Provided Quantity</th>
                                    <th>Operator Name</th>
                                    <th>Request Date</th>
                                    <th>Close Date</th>
                                    <th>Remark</th>
                                    <th>Is Rejected</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.stores.length === 0 ? (
                                    <div></div>
                                ) : (
                                    this.state.stores.map((store, idx) => (
                                        <tr key={idx}>
                                            <td>{store.materialName}</td>
                                            <td>{store.orderQuantity}</td>
                                            <td>{store.suppliedQuantity}</td>
                                            <td>{store.operatorName}</td>
                                            <td>{store.requestedDate}</td>
                                            <td>{store.closedDate}</td>
                                            <td>{store.remark}</td>
                                            <td>{store.isRejected?('Yes'):('No')}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </Row>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <Pagination
                        hideNavigation
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        itemClass='page-item'
                        linkClass='btn btn-light'
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Container>
        );
    }
}
